<div
  class="table-sorter"
  [ngClass]="{'sorting-selected': currentOrder}"
  (mouseleave)="hideDropdown()">
  <span
    (click)="showSortingDropdown()"
    class="table-sorter__title">{{title}}</span>
  <div class="table-sorter__arrows">
    <svg
      [ngClass]="{'selected': currentOrder && currentOrder === 'asc'}"
      class="down-arrow" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/></svg>
    <svg
      [ngClass]="{'selected': currentOrder && currentOrder === 'desc'}" 
      id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/></svg>
  </div>
  <div
    *ngIf="showDropdown"
    [ngClass]="{'pos-left': dropdownPosition && dropdownPosition.left, 'pos-right': dropdownPosition && dropdownPosition.right}"
    class="table-sorter__dropdown">
    <ul class="table-sorter__dropdown__list">
      <li
        class="table-sorter__dropdown__list__element"
        *ngFor="let type of orderTypes"
        (click)="setSortType(type.value)"
        [ngClass]="{'default-sort': type.value === 'default'}">
          <span class="table-sorter__dropdown__list__element__icon" [ngSwitch]="type.value">
            <svg *ngSwitchCase="'asc'" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;}</style></defs><g data-name="Layer 2" id="Layer_2"><path d="M25,14a1,1,0,0,1-.71-.29L16,5.41l-8.29,8.3a1,1,0,0,1-1.42-1.42l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,0,1.42A1,1,0,0,1,25,14Z"/><path d="M16,29a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V28A1,1,0,0,1,16,29Z"/></g><g id="frame"><rect class="cls-1" height="32" width="32"/></g></svg>
            <svg *ngSwitchCase="'desc'" [ngClass]="{'desc-icon': type.value === 'desc'}" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;}</style></defs><g data-name="Layer 2" id="Layer_2"><path d="M25,14a1,1,0,0,1-.71-.29L16,5.41l-8.29,8.3a1,1,0,0,1-1.42-1.42l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,0,1.42A1,1,0,0,1,25,14Z"/><path d="M16,29a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V28A1,1,0,0,1,16,29Z"/></g><g id="frame"><rect class="cls-1" height="32" width="32"/></g></svg>
            <svg *ngSwitchCase="'default'" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;}</style></defs><g data-name="Layer 2" id="Layer_2"><path d="M8,21a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1,0-1.42l5.18-5.17a10.59,10.59,0,0,1,10.84-2.6A1,1,0,0,1,20,8.78a1,1,0,0,1-1.26.64,8.66,8.66,0,0,0-8.81,2.11L5.41,16l3.3,3.29a1,1,0,0,1,0,1.42A1,1,0,0,1,8,21Z"/><path d="M16,25A10.61,10.61,0,0,1,9.5,22.79a1,1,0,1,1,1.22-1.58,8.69,8.69,0,0,0,11.4-.74L26.59,16l-4.47-4.47a9,9,0,0,0-.74-.66,1,1,0,1,1,1.24-1.56,8.77,8.77,0,0,1,.91.81l5.18,5.17a1,1,0,0,1,0,1.42l-5.18,5.17A10.62,10.62,0,0,1,16,25Z"/><path d="M16,20a4,4,0,0,1-2.67-1,1,1,0,1,1,1.34-1.49A2,2,0,0,0,16,18a2,2,0,0,0,2-2,2,2,0,0,0-.51-1.33A1,1,0,1,1,19,13.33,4,4,0,0,1,16,20Z"/><path d="M13.17,16a1,1,0,0,1-.33-.06,1,1,0,0,1-.61-1.27,3.94,3.94,0,0,1,2.44-2.44,1,1,0,0,1,.66,1.88,2,2,0,0,0-1.22,1.22A1,1,0,0,1,13.17,16Z"/><path d="M8,25a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l16-16a1,1,0,1,1,1.42,1.42l-16,16A1,1,0,0,1,8,25Z"/></g><g id="frame"><rect class="cls-1" height="32" width="32"/></g></svg>
          </span>
          <span class="table-sorter__dropdown__list__element__label">{{type.label}}</span>
      </li>
    </ul>
  </div>
</div>
