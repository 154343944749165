<h3>Channels list</h3>

<form>
  <div class="grid-x">
    <div class="input-group cell medium-12">
        <input [formControl]="searchParam" name="searchParam" class="input-group-field" type="text" placeholder="Search Parameter">
        <div class="input-group-button">
          <button id="search-button" (click)="search()" type="submit" class="button primary">Search</button>
          <button *ngIf="searchString !== ''" type="button" (click)="resetSearch()" class="button secondary">Clear</button>
        </div>
    </div>        
  </div>
</form>
<ng-container *ngIf="channels && channels.length > 0">
  <div class="button-group upper-table-actions">
    <div class="number-of-results">
      <span class="number-of-results__label">Results found:</span>
      <span class="number-of-results__number">{{this.searchResultsTotal}}</span>
    </div>
  </div>
  
  <table class="channels-table">
    <thead>
      <tr>
        <!-- <th>
          <postd-table-sorter
            [title]="'Display Name'"
            [sortProp]="sorters[0]"
            [dropdownPosition]="{left: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th>
        <th>
          <postd-table-sorter
            [title]="'Email'"
            [sortProp]="sorters[1]"
            [dropdownPosition]="{left: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th> -->
        <th>#</th>
        <th>Name</th>
        <th>Owner</th>
        <th>Status</th>
        <th>Posts</th>
        <th>Subs</th>
        <th>Rating</th>
        <th>Views</th>
        <th>Created</th>
        <!-- <th>
          <postd-table-sorter
            [title]="'Created'"
            [sortProp]="sorters[2]"
            [dropdownPosition]="{right: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ch of channels; let i = index" [routerLink]="['/channels', ch.id, 'view']" >
        <td>{{ i + 1 }}</td>
        <td>{{ ch.name }}</td>
        <td>
          <span *ngIf="!ch.owner">Loading owner data...</span>
          <span *ngIf="ch.owner">
            {{ ch.owner.firstName && ch.owner.lastName ? ch.owner.firstName + ' ' + ch.owner.lastName : ch.owner.displayName }}
          </span>
        </td>
        <td class="status">
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['Invalid']">Invalid</span>
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['Default']">Default</span>
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['OpenCommunity']">Open</span>
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['InviteOnlyCommunity']">Invite</span>
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['PrivateCommunity']">Private</span>
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['Free']">Free</span>
          <span class="state-tag" *ngIf="ch['kind'] === channelKind['Last']">Last</span>
        </td>
        <td>{{ ch.numPosts }}</td>
        <td>{{ ch.numSubs }}</td>
        <td>{{ ch.ratingAvg | number: '1.1-2' }}</td>
        <td>{{ ch.numViews }}</td>
        <td>{{ ch.created | date: 'short'}}</td>
      </tr>
    </tbody>
  </table>

</ng-container>
<div *ngIf="!loading && channels && channels.length === 0" data-alert class="alert-box info radius">
  No channels found
</div>
<postd-spinner [show]="loading"></postd-spinner>
