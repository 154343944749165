import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CacheService } from '@shared/services/cache_service';
import { ChannelService as ClientChannelService } from '@shared/services/channel_service';

import { UserService } from './user.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ChannelService extends ClientChannelService {
  constructor(
    httpClient: HttpClient,
    userService: UserService,
    cacheService: CacheService,
    @Inject(DOCUMENT) document: Document
  ) {
    super(httpClient, cacheService, userService, document);
  }
}
