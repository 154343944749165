<ng-container [ngSwitch]="pageMode">
  <h3 *ngSwitchCase="FlaggedPostsTypes.invalid">Flagged Invalid Posts</h3>
  <h3 *ngSwitchCase="FlaggedPostsTypes.valid">Flagged Valid Posts</h3>
  <h3 *ngSwitchDefault>Flagged Posts</h3>
</ng-container>

<ng-container *ngIf="postList && postList.length > 0">
  <table>
    <thead>
      <tr>
        <th>Post Name</th>
        <th>Post Channel</th>
        <th>Flag Reason</th>
        <th>Flag Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of postList">
        <td><a (click)="openPostDetail(p)">{{ p.name }}</a></td>
        <td><a [routerLink]="['/channels', p.channelId, 'view']">{{ p.channelInfo.name }}</a></td>
        <td>{{ p.flagInfo.reasonStrings.join(', ') }}</td>
        <td>{{ p.flagInfo.created | date: 'short' }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
<div *ngIf="!loading && postList && postList.length === 0" data-alert class="alert-box info radius">
  No posts found
</div>
<ng-template
  #postDetailModal
  [postdModalTemplate]="{ 'contentClassName': 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-post-view *ngIf="data.postDetail" [post]="data.postDetail">
    <div class="callout warning" *ngIf="data.flagInfo">
      <h5>User Flag</h5>
      <table class="compact">
        <thead>
          <th>Reason</th>
          <th>Comment</th>
        </thead>
        <tbody>
          <tr>
            <td>{{ data.flagInfo.reasonStrings.join(', ') }}</td>
            <td class="flag-comment">{{ data.flagInfo.comments }}</td>
          </tr>
        </tbody>
      </table>
      <div class="grid-x grid-margin-x">
        <div class="cell shrink">
          <div class="button-group">
            <button
              class="button secondary"
              [ngClass]="{ success: data.setActionTaken === FlagAction.PostTakedown }"
              (click)="!data.flagResolved && data.setActionTaken = FlagAction.PostTakedown"
            >
              Accept Flag
            </button>
            <button
              class="button secondary"
              [ngClass]="{ success: data.setActionTaken === FlagAction.FlagRejected }"
              (click)="!data.flagResolved && data.setActionTaken = FlagAction.FlagRejected"
            >
              Reject Flag
            </button>
          </div>
        </div>
        <div class="cell shrink">
          <button
            *ngIf="!data.flagResolved"
            class="button"
            [disabled]="!data.setActionTaken"
            (click)="resolveFlag(data.flagInfo, data.setActionTaken)"
          >
            Submit
          </button>
        </div>
        <div class="cell auto" *ngIf="data.error">
          <span *ngIf="data.error === 409; else otherError" class="error">This flag has already been resolved.</span>
          <ng-template #otherError>
            <span class="error">There was a problem setting the flag.</span>
          </ng-template>
        </div>
      </div>
    </div>
  </postd-bo2-post-view>
</ng-template>
<postd-spinner [show]="loading"></postd-spinner>
