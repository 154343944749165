import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableSorterComponent } from './table-sorter.component';

@NgModule({
    declarations: [
        TableSorterComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TableSorterComponent
    ]
})
export class TableSorterModule {
}
