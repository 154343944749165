import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Channel } from '@shared/types/channel';
import type { Post } from '@shared/types/post';

export interface PaymentRecord {
  processor: number;
  currency: string;
  accounted: number;
  id: string;
  postId: string;
  channelId: string;
  buyerId: number;
  sellerId: number;
  publisherId: number;
  state: number;
  payType: number;
  token: unknown;
  price: number;
  commission_cc: number;
  usrEarns_cc: number;
  created: number;
  updated: number;
  ip_adress: string;
  pIdBuyerIdx: string;
  channel?: Channel;
  post?: Post;
  buyer?: PaymentUser;
  seller: PaymentUser;
}

interface PaymentUser {
  bio: string;
  accountClosed: number;
  reputation: number;
  id: number;
  displayName: string;
  profileImgThbs: Record<string, string>;
  created: number;
  profileImageUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getLatest(page = 0, pageSize = 50) {
    const url = '/api/admin/payments/latest?fromOffset=' + page * pageSize + '&size=' + pageSize;
    return this.http.get<PaymentRecord[]>(url);
  }
}
