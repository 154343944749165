<h3>Latest Payments</h3>

<ng-container *ngIf="payments | async as paymentsList">
  <table *ngIf="paymentsList.length > 0">
    <thead>
      <tr>
        <th>Channel</th>
        <th>Post</th>
        <th>Buyer</th>
        <th>Price</th>
        <th>Term</th>
        <th>Date</th>
        <th>IP Address</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of paymentsList">
        <td class="align-top">
          <a [routerLink]="['/channels', p.channelId, 'view']">{{ p.channel?.name }}</a>
        </td>
        <td class="align-top" *ngIf="p.post">
          <a (click)="openPostDetail(p.post)">{{ p.post?.name }}</a>
        </td>
        <td class="align-top" *ngIf="!p.post">Subscription</td>
        <td class="align-top">{{ p.buyer?.displayName }}</td>
        <td class="align-top">{{ p.price / 100 | currency : 'USD' : 'symbol' }}</td>
        <td class="align-top"></td>
        <td class="align-top">
          {{ p.created | date : 'short' }}
        </td>
        <td class="align-top">{{ p.ip_adress }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!loading && paymentsList.length === 0" class="alert-box info radius">
    No payments found
  </div>
  <button class="button primary" (click)="loadMore()">Load More</button>
</ng-container>
<ng-template
  #postDetailModal
  [postdModalTemplate]="{ contentClassName: 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-post-view *ngIf="data.postDetail" [post]="data.postDetail"></postd-bo2-post-view>
</ng-template>

<postd-spinner [show]="loading"></postd-spinner>
