<ng-container *ngIf="post | async as p">
  <h4>
    {{ p.name }}
    <a class="external-link" [attr.href]="postdURLBase + '/post/' + p.id + '/'" target="_blank">
      <fa-icon [icon]="['fas', 'external-link']" title="Show Post"></fa-icon>
    </a>
  </h4>
  <ng-content></ng-content>
  <table class="compact">
    <thead>
      <th></th>
      <th>Channel</th>
      <th>User</th>
      <th>Created</th>
      <th>Updated</th>
      <th>Published</th>
    </thead>
    <tbody>
      <tr>
        <td>
          <img *ngIf="p.thumbnailImage" [src]="p.thumbnailImage" style="max-width: 150px" />
        </td>
        <td>
          <a [routerLink]="['/channels', p.channelId, 'view']">{{ (channel | async)?.name }}</a>
        </td>
        <td>
          <a [routerLink]="['/users', p.userId, 'view']">{{ (user | async)?.displayName }}</a>
        </td>
        <td>{{ p.created | date : 'short' }}</td>
        <td>{{ p.updated | date : 'short' }}</td>
        <td>{{ p.published | date : 'short' }}</td>
      </tr>
    </tbody>
  </table>
  <table class="compact">
    <thead>
      <th>Price</th>
      <th>State</th>
      <th>Purchases</th>
      <th>Views</th>
      <th>Comments</th>
      <th>Age Rating</th>
      <th>Abuse</th>
    </thead>
    <tbody>
      <tr>
        <td>{{ p.price / 100 | currency : 'USD' : 'symbol' : '1.2-2' }}</td>
        <td>{{ p.state }}</td>
        <td>{{ p.purchaseCount }}</td>
        <td>{{ p.viewCount }}</td>
        <td>{{ p.commentsCount }}</td>
        <td>{{ p.minAge }}</td>
        <td>{{ p.abuseCount }}</td>
      </tr>
    </tbody>
  </table>
  <div class="callout primary">
    <div class="modal-actions">
      <div class="grid-x">
        <div class="cell auto">
          <div class="button-group" *ngIf="!loadingFeatured && p.featured">
            <button class="button primary">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
              Currently Featured
            </button>
            <button class="button secondary" (click)="featuredPostToggle(p)">Remove</button>
          </div>
          <button
            *ngIf="!loadingFeatured && !p.featured"
            class="button primary"
            (click)="featuredPostToggle(p)"
          >
            <fa-icon [icon]="['far', 'star']"></fa-icon>
            Feature Post
          </button>
          <button *ngIf="loadingFeatured" class="button secondary" disabled>
            <fa-icon [icon]="['fas', 'circle-notch']" [spin]="true"></fa-icon>
            Loading
          </button>
        </div>
        <div *ngIf="p.state === PostState.TRASH" class="cell shrink">
          <button class="button secondary" (click)="setState(p, PostState.HIDDEN)">Restore</button>
        </div>
        <div class="cell shrink">
          <button
            [disabled]="p.state === PostState.TAKEN_DOWN"
            class="button alert"
            (click)="setState(p, PostState.TAKEN_DOWN)"
          >
            Suspend Post
          </button>
        </div>
        <div class="cell shrink">
          <button
            *ngIf="p.state === PostState.HIDDEN"
            class="button secondary"
            (click)="setState(p, PostState.PUBLIC)"
          >
            Publish Post
          </button>
          <button
            *ngIf="p.state === PostState.PUBLIC"
            class="button secondary"
            (click)="setState(p, PostState.HIDDEN)"
          >
            Unpublish Post
          </button>
        </div>
      </div>
    </div>
  </div>
  <ul class="accordion">
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections[0] }">
      <a (click)="pageSections[0] = !pageSections[0]" class="accordion-title">Lead Content</a>

      <div class="accordion-content" [innerHTML]="leadContent | async"></div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections[1] }">
      <a (click)="pageSections[1] = !pageSections[1]" class="accordion-title">Premium Content</a>

      <div class="accordion-content" [innerHTML]="premiumContent | async"></div>
    </li>
  </ul>
</ng-container>
