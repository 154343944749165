import { Subject } from 'rxjs';
import { Directive, Renderer2, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import * as _ from 'lodash';

export class ModalContext {
  constructor(private dialogInstance: ModalDirective, private data: any) { }
}

const defaultOptions = {
  className: 'vex-theme-plain'
};

@Directive({
  selector: '[postdModalTemplate]'
})
export class ModalDirective {
  @Input() postdModalTemplate: any; // VexOptions;
  openInstance: any; // VexInstance;
  data: any;

  constructor(
    private renderer: Renderer2,
    private templateRef: TemplateRef<ModalContext>,
    private viewContainerRef: ViewContainerRef,
    @Inject('vex') private vex: any,
  ) {
    this.postdModalTemplate = {};
  }

  open(customOptions: any = {}, customData?: any) {
    const closeSubject = new Subject<void>();
    const options = {
      ...defaultOptions,
      ...this.postdModalTemplate,
      ...customOptions,
      afterClose: () => {
        closeSubject.next();
        closeSubject.complete();

        if (customOptions.afterClose) {
          customOptions.afterClose();
        }
      }
    };

    if (customData) {
      this.data = customData;
    }

    this.openInstance = this.vex.open(options);

    const template = this.viewContainerRef.createEmbeddedView(this.templateRef, new ModalContext(this, this.data));
    template.rootNodes.forEach((node) => {
      this.renderer.appendChild(this.openInstance.contentEl, node);
    });

    return closeSubject;
  }

  close() {
    if (this.openInstance) {
      this.openInstance.close();
      this.openInstance = null;
    }
  }

}
