<ng-container *ngIf="channel | async as ch">
  <h3>
    <div class="grid-x">
      <div class="cell auto">
        <a (click)="location.back()"
          ><fa-icon [icon]="['fas', 'arrow-left']" title="Go Back"></fa-icon
        ></a>
        Channel {{ ch.name }}
      </div>
      <div class="cell shrink">
        <a class="button alert" (click)="deleteChannel(ch.id)">Delete Channel</a>
      </div>
    </div>
  </h3>
  <ul class="accordion">
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections[0] }">
      <a (click)="pageSections[0] = !pageSections[0]" class="accordion-title"
        >General Information</a
      >

      <div class="accordion-content">
        <table class="compact">
          <thead>
            <th>Avatar</th>
            <th>Banner</th>
            <th>Description</th>
            <th>Created</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <img
                  *ngIf="ch.iconImageUrl"
                  [src]="ch.iconImageUrl"
                  style="width: 150px; height: 150px; object-fit: cover"
                />
              </td>
              <td>
                <img
                  *ngIf="ch.bannerImageUrl"
                  [src]="ch.bannerImageUrl"
                  style="max-height: 150px"
                />
              </td>
              <td>{{ ch.description }}</td>
              <td>{{ ch.created | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
        <table class="compact">
          <thead>
            <th>Owner</th>
            <th>Channel Tags</th>
          </thead>
          <tbody>
            <tr>
              <td style="padding-right: 15px">
                <a [routerLink]="['/users', ch.userId, 'view']">{{
                  (user | async)?.displayName
                }}</a>
              </td>
              <td>{{ ch.postTags.join(', ') }}</td>
            </tr>
          </tbody>
        </table>
        <table class="compact">
          <thead>
            <th>Post Count</th>
            <th>Purchase Count</th>
            <th>View Count</th>
            <th>Follower Count</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ ch.numPosts }}</td>
              <td>{{ ch.numPurchases }}</td>
              <td>{{ ch.numViews }}</td>
              <td>{{ ch.followers }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections[1] }">
      <a name="posts" (click)="pageSections[1] = !pageSections[1]" class="accordion-title">Posts</a>

      <div class="accordion-content">
        <table>
          <thead>
            <tr>
              <th class="text-center">Name</th>
              <th class="text-center">State</th>
              <th class="text-center">Featured</th>
              <th class="text-center">Purchased</th>
              <th class="text-center">Created</th>
              <th class="text-center">Updated</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of postList">
              <td class="text-center">
                <a (click)="openPostDetail(p)">{{ p.name }}</a>
              </td>
              <td class="text-center">{{ p.state }}</td>
              <td class="text-center">
                <a *ngIf="!p.featured" (click)="postFeatureToggle(p)" class="status">
                  <fa-icon
                    *ngIf="featuredPostData.loading && featuredPostData.post.id === p.id"
                    [icon]="['fas', 'circle-notch']"
                    [spin]="true"
                  ></fa-icon>
                  Set as Featured
                </a>
                <a *ngIf="p.featured" (click)="postFeatureToggle(p)" class="status selected">
                  <fa-icon
                    *ngIf="featuredPostData.loading && featuredPostData.post.id === p.id"
                    [icon]="['fas', 'circle-notch']"
                    [spin]="true"
                  ></fa-icon>
                  Remove as Featured
                </a>
              </td>
              <td class="text-center">{{ p.purchaseCount }}</td>
              <td class="text-center">{{ p.created | date : 'short' }}</td>
              <td class="text-center">{{ p.updated | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections[2] }">
      <a name="playlists" (click)="pageSections[2] = !pageSections[2]" class="accordion-title"
        >Playlists</a
      >

      <div class="accordion-content">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Post Count</th>
              <th>View Count</th>
              <th>Created</th>
              <th>Updated</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of playlists | async">
              <td>
                <a (click)="openPlaylistDetail(p)">{{ p.name }}</a>
              </td>
              <td>{{ p.postIds.length }}</td>
              <td>{{ p.viewCount }}</td>
              <td>{{ p.created | date : 'short' }}</td>
              <td>{{ p.updated | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections[3] }">
      <a name="sales" (click)="pageSections[3] = !pageSections[3]" class="accordion-title">Sales</a>

      <div class="accordion-content">
        <table>
          <thead>
            <tr>
              <th class="text-center">User name</th>
              <th class="text-center">User e-mail</th>
              <th class="text-center">Purchase IP</th>
              <th class="text-center">Post</th>
              <th class="text-center">Price</th>
              <th class="text-center">Created</th>
              <th class="text-center">Transact</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let s of sales">
              <td class="text-center">
                <a
                  [routerLink]="['/users', saleUser.id, 'view']"
                  [title]="'See user profile'"
                  *ngIf="s.user | async as saleUser"
                >
                  {{
                    saleUser.firstName && saleUser.lastName
                      ? saleUser.firstName + ' ' + saleUser.lastName
                      : saleUser.displayName
                  }}
                </a>
                <span *ngIf="!(s.user | async)">Loading user data....</span>
              </td>
              <td class="text-center">
                <a
                  *ngIf="s.user | async as saleUser"
                  [routerLink]="['/users', saleUser?.id, 'view']"
                  [title]="'See user profile'"
                  >{{ saleUser.email1 }}</a
                >
              </td>
              <td class="text-center">
                {{ s.ip_adress || 'not included' }}
              </td>
              <td class="text-center">
                <a
                  [routerLink]="['/posts', salePost.id, 'view']"
                  [title]="'See user profile'"
                  *ngIf="s.postFetch | async as salePost"
                >
                  {{ salePost.name }}
                </a>
              </td>
              <td class="text-center">
                {{ s.price / 100 | currency : 'USD' : 'symbol' : '1.2-2' }}
              </td>
              <td class="text-center">{{ s.created | date : 'short' }}</td>
              <td class="text-center">
                <a href="{{ s.transactUserURL }}" target="_blank">
                  <fa-icon [icon]="['far', 'link']"></fa-icon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</ng-container>
<ng-template
  #postDetailModal
  [postdModalTemplate]="{ contentClassName: 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-post-view *ngIf="data.postDetail" [post]="data.postDetail"></postd-bo2-post-view>
</ng-template>
<ng-template
  #playlistDetailModal
  [postdModalTemplate]="{ contentClassName: 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-playlist-view
    *ngIf="data.playlistDetail"
    [playlist]="data.playlistDetail"
  ></postd-bo2-playlist-view>
</ng-template>
