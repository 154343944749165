<div [ngClass]="[(simple ? 'simple' : ''), ' dropdown-group ' + (dropdownClass || '')]" 
  class="{{isDropdownOpened()}}">
  <div class="dropdown-container" [hidden]="!dropdownOpen">
    <a (click)="toggleDropdown()" class="close-dropdown" title="Close Dropdown">
      <fa-icon [icon]="['fas', 'times']" aria-hidden="true"></fa-icon>
    </a>
    <div class="dropdown-content {{position ? 'position-' + position : ''}}" #dropdownContent>
      <ng-container *ngIf="dropdownOpen"><ng-content select=".dropdown"></ng-content></ng-container>
    </div>
  </div>
  <span [ngClass]="{disabled: disabled}" (click)="toggleDropdown()"><ng-content select="a.dropdown-label"></ng-content></span>
</div>
<div class="dropdown-close-overlay" *ngIf="dropdownOpen" (click)="toggleDropdown()"></div>
