import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Bank } from '@shared/types/bank';
import { Payout, PayoutStatus } from '@shared/types/payout';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  payoutStatus = PayoutStatus;

  constructor(private http: HttpClient) {}

  getPayouts(status: PayoutStatus) {
    const url = '/api/admin/payout/list/status/' + status.valueOf();
    return this.http.get<Payout[]>(url, {});
  }

  getUserPayouts(userId: number) {
    const url = '/api/user/' + userId.toString() + '/payouts/list';
    return this.http.get<Payout[]>(url, {});
  }

  getUserBankingInfo(userId: number) {
    return this.http.get<Bank[]>(`/api/user/${userId}/profile/bank/list`);
  }

  updatePayout(id: string, notes: string, status: PayoutStatus) {
    const url = '/api/admin/payout/' + id + '/update';
    return this.http.post<Payout>(url, {
      staffNotes: notes,
      status: status.valueOf().toString(),
    });
  }
}
