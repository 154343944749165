import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[postdCloseOnClick]'
})
export class CloseOnClickDirective {
  @Input() postdCloseOnClick: string;

  private clickStateSource = new BehaviorSubject<boolean>(undefined);
  clickState: boolean;
  clickState$ = this.clickStateSource.asObservable();

  constructor(private el: ElementRef) {
    this.clickStateSource.next(false);
  }
  @HostListener('click') onElementClicked() {
    this.click();
  }

  private click() {
    this.clickStateSource.next(true);

    setTimeout(() => {
      this.clickStateSource.next(false);
    });
  }
}
