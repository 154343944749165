import { FundsMap } from './funds_map';
import { Address } from './address';
import { LegalEntityType } from './legal_entity';
import { ThumbnailResolutions } from './thumbnail_resolutions';

export class User {
  public firstName: string;
  public lastName: string;
  public displayName: string;
  public email1: string;
  public accountClosed: boolean;
  public id: number;
  public xsactUserId: string;
  public profileImageUrl: string;
  public profileImgThbs?: ThumbnailResolutions;
  public admin: boolean;

  public chOwned: { [key: string]: string };
  public chFollow: { [key: string]: any };

  public notifyFollow: number;  // notify new following events
  public notifyMyPosts: number;  // notify about my posts, comments, etc
  public notifyMyComms: number;  // notify about my comments
  public reputation: number;

  public funds: FundsMap;  // dictionary currency / funds

  public entityType: LegalEntityType;
  public fullLegalName; // full legal name
  public legalAddress: Address;
  public taxDoc: string; // tax document
  public identityDoc: string; // tax document
  public created: number;
  public updated: number;
  public preferences?: {
    metricUnits?: 0 | 1;
    emailNotifications?: 0 | 1;
    notifyPostComments?: 0 | 1;
  };

  /* ???? what are these
  id: string;
  display_name: string; // organization name, of group, or of user for 1st registration
  secret1: string; // secret for publishers/sellers
  address : Object; // user or company address
  authResponse : Object[];
  profile_image: string;*/

  constructor(userData?: any) {
    if (userData) {
      const keys = Object.keys(userData);
      keys.forEach( k => {
        this[k] = userData[k];
      });
    }
  }
}
