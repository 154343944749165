import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PostdSpinnerModule } from '@shared/components/postd-spinner/postd-spinner.module';

import { SharedDirectivesModule } from './../../app/common/directives.module';
import { PostsModule } from './../posts/posts.module';
import { ChannelsBrowseComponent } from './browse/browse.component';
import { ChannelViewComponent } from './view/view.component';
import { ChannelPlaylistComponent } from './playlist/playlist.component';

@NgModule({
  declarations: [ ChannelsBrowseComponent, ChannelViewComponent, ChannelPlaylistComponent ],
  exports: [ ChannelsBrowseComponent, ChannelViewComponent, ChannelPlaylistComponent ],
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    InfiniteScrollModule,
    PostdSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SharedDirectivesModule,
    PostsModule
  ]
})
export class ChannelsModule { }
