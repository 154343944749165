import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { ProfileImageModule } from './../../app/common/profile-image.module';
import { SharedDirectivesModule } from './../../app/common/directives.module';
import { HeaderComponent } from './header/header.component';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { UndoActionsComponent } from './undo-actions/undo-actions.component';

@NgModule({
  declarations: [ HeaderComponent, LeftNavComponent, UndoActionsComponent ],
  exports: [ HeaderComponent, LeftNavComponent, UndoActionsComponent ],
  imports: [
    RouterModule,
    CommonModule,
    DropdownModule,
    FontAwesomeModule,
    ProfileImageModule,
    SharedDirectivesModule
  ]
})
export class CoreModule { }
