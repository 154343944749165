import { Component, OnDestroy, ViewChild } from '@angular/core';

import { PaymentRecord, PaymentsService } from '../services/payments.service';
import { BehaviorSubject, Observable, concatMap, from, map, tap } from 'rxjs';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { Post } from '@shared/types/post';
import { PostService } from '../services/post.service';

@Component({
  selector: 'postd-bo2-payments-list',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsListComponent implements OnDestroy {
  loading = true;
  payments: Observable<PaymentRecord[]>;
  page = new BehaviorSubject<number>(0);
  @ViewChild('postDetailModal', { read: ModalDirective }) postDetailModal: ModalDirective;

  private results: PaymentRecord[] = [];

  constructor(private paymentsService: PaymentsService, private postService: PostService) {
    this.payments = this.page.pipe(
      tap(() => (this.loading = true)),
      concatMap((page) => this.paymentsService.getLatest(page)),
      map((payments) => {
        console.log(payments);
        console.log(payments.filter((p) => p.postId === undefined));
        this.results = this.results.concat(payments);
        return this.results;
      }),
      tap(() => (this.loading = false))
    );
  }

  ngOnDestroy(): void {
    this.postDetailModal.close();
  }

  loadMore() {
    this.page.next(this.page.value + 1);
  }

  openPostDetail(post: Post) {
    this.postDetailModal.open(
      {},
      {
        postDetail: from(this.postService.fetchPost(post.id)),
      }
    );
  }
}
