import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CacheService } from '@shared/services/cache_service';
import { UserService as ClientUserService } from '@shared/services/user_service';
import { User } from '@shared/types/user';
import { SaleInfo } from '@shared/types/sale';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ClientUserService {
  constructor(
    private httpClient: HttpClient,
    router: Router,
    activeRoute: ActivatedRoute,
    cacheService: CacheService
  ) {
    super(httpClient, router, activeRoute, cacheService);
  }

  getUsers(
    searchParam?: string,
    fromOffset?: number,
    pageSize?: number,
    sort?: string
  ): Observable<{ users: User[]; total: number }> {
    let qParams = '';
    if (searchParam && searchParam.length > 0) {
      qParams += '&q=' + encodeURI(searchParam);
    }
    if (fromOffset) {
      qParams += '&fromOffset=' + fromOffset;
    }
    if (pageSize) {
      qParams += '&size=' + pageSize;
    }
    if (sort) {
      qParams += '&sort=' + sort;
    }
    if (qParams.length > 0) {
      qParams = '?' + qParams.slice(1);
    }
    return this.httpClient.get<any>('/api/admin/user/simple/query' + qParams, {}).pipe(
      map((response) => ({
        users: response.users,
        total: response.total,
      }))
    );
  }

  getUserSales(userId: number, page = 0, perPage = 100) {
    return this.httpClient.get<SaleInfo[]>(
      `/api/user/${userId}/sales?fromOffset=${page * perPage}&limit=${perPage}`
    );
  }

  deleteUser(userId: number) {
    return this.httpClient.delete<any>('/api/user/' + userId + '/profile/delete');
  }
}
