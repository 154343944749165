import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root'
})
export class BackofficeAuthGuard  {
  constructor(private userService: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getProfile().then(user => {
      if(!user.id) {
        this.userService.login();
        return false;
      } else if(!user.admin) {
        this.userService.logout().then(() => {
          this.userService.login();
        });
        return false;
      } else {
        return true;
      }
    });
  }
}
