import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from './../../services/user.service';
import { Channel } from '@shared/types/channel';
import { ChannelService } from './../../services/channel.service';
import { User } from '@shared/types/user';

const CHANNELS_PER_PAGE = 20;
const channelsLists = new Map<string, Channel[]>();

export enum ChannelKind {
  Invalid = -1,
  Default = 0, // default channel that
  OpenCommunity = 1, // Open community anyone can join
  InviteOnlyCommunity = 2, // Invite required to join. Community is visible, searchable
  PrivateCommunity = 3, // Community is invisible to searching, can only be added by invite
  Free = 4, // FREE, for use only by ourselves for our own publicity, PR, info, etc
  InviteOnly = 5, // Stream where only owner can post, and is invite only
  Last = 6, // end of enum
}

export interface CustomChannel extends Channel {
  owner?: User;
}

@Component({
  selector: 'postd-bo2-channels-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class ChannelsBrowseComponent implements OnDestroy, OnInit {
  searchParam: UntypedFormControl;
  searchString = '';
  channels?: CustomChannel[];
  // page: number;
  endOfResults = false;
  loading = false;
  destroy = new Subject<void>();
  public channelKind = ChannelKind;
  public searchResultsTotal: number;
  public resetSorters: string[];
  public page = 0;
  public pageSize = 40;
  private sorters = ['displayName', 'email1', 'createdDate'];
  private sortingOrder: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private channelService: ChannelService
  ) {
    this.searchParam = new UntypedFormControl();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy))
      .subscribe(params => {
        if (params.query) {
          this.searchString = params.query;
        } else {
          this.searchString = '';
        }
        if (this.searchParam.value !== params.query) {
          this.searchParam.setValue(params.query);
        }

        this.reset();
      });
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  loadChannels(resetList?: boolean) {
    if (this.endOfResults) {
      return;
    }
    this.loading = true;

    const query = {
      q: this.searchParam.value || '',
      sort: '',
      // fromOffset: this.page * CHANNELS_PER_PAGE,
      // size: CHANNELS_PER_PAGE
    };

    this.channelService.getChannels(query).then(result => {
      this.searchResultsTotal = result.total || 0;
      if (!result.channels || result.channels.length === 0) {
        this.endOfResults = true;
      } else {
        if (resetList) {
          this.channels = result.channels || [];
        } else {
          this.channels.push(...result.channels);
        }
      }

      for (const c of this.channels) {
        this.userService.getUser(c.userId).subscribe((user: User) => {
          c.owner = user;
        });
      }

      this.loading = false;
    });
    this.page++;
  }

  search() {
    this.router.navigate(['/', 'channels'], { queryParams: { query: this.searchParam.value } });
  }

  resetSearch() {
    this.searchParam.setValue('');
    this.router.navigate(['/', 'channels'], { queryParams: { query: '' } });
  }

  private reset() {
    let channels = channelsLists.get(this.searchString);
    if (!channels) {
      channels = [];
      channelsLists.set(this.searchString, channels);
    }
    this.channels = channels;

    this.page = 0;
    this.endOfResults = false;
    this.loadChannels();
  }

  public sortingChanged(ev: any): void {
    this.sortingOrder = (ev.sortType && ev.sortType !== 'default') ?
      ev.sorter + ev.sortType.replace(ev.sortType[0], ev.sortType[0].toUpperCase()) : '';

    this.resetSorters = this.sorters.filter(s => s !== ev.sorter);
    this.loading = true;
    this.page = 0;
    this.loadChannels(true);
  }
}
