<h3>Featured Posts</h3>

<ng-container *ngIf="postList | async as posts">
  <table *ngIf="posts.length > 0">
    <thead>
      <tr>
        <th></th>
        <th>Post Name</th>
        <th>Post Channel</th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="postsSorted($event, posts)">
      <tr *ngFor="let p of posts" cdkDrag>
        <td class="drag-handle"><fa-icon class="icon" [icon]="['fas', 'ellipsis-v']"></fa-icon></td>
        <td>
          <a (click)="openPostDetail(p)">{{ p.name }}</a>
        </td>
        <td>
          <a [routerLink]="['/channels', p.channelId, 'view']">{{ p.channelName }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="posts.length === 0" data-alert class="alert-box info radius">No posts found</div>
</ng-container>
<ng-template
  #postDetailModal
  [postdModalTemplate]="{ contentClassName: 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-post-view *ngIf="data.postDetail" [post]="data.postDetail"></postd-bo2-post-view>
</ng-template>
<postd-spinner [show]="loading"></postd-spinner>
