
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PostdSpinnerModule } from '@shared/components/postd-spinner/postd-spinner.module';

import { UsersBrowseComponent } from './browse/browse.component';
import { UserViewComponent } from './view/view.component';
import { UserEditComponent } from './edit/edit.component';
import { SharedDirectivesModule } from './../../app/common/directives.module';
import { PayoutsModule } from './../payouts/payouts.module';
import { TableSorterModule } from '@shared/components/table-sorter/table-sorter.module';
import { PostdPaginationModule } from '@shared/components/pagination/pagination.module';

@NgModule({
  declarations: [UsersBrowseComponent, UserViewComponent, UserEditComponent],
  imports: [
    RouterModule,
    CommonModule,
    InfiniteScrollModule,
    PostdSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SharedDirectivesModule,
    PayoutsModule,
    TableSorterModule,
    PostdPaginationModule
  ]
})
export class UsersModule { }
