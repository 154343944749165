<h3>Browse Users</h3>

<form>
  <div class="grid-x">
    <div class="input-group cell medium-12">
        <input [formControl]="searchParam" name="searchParam" class="input-group-field" type="text" placeholder="Search Parameter">
        <div class="input-group-button">
          <button id="search-button" (click)="search()" type="submit" class="button primary">Search</button>
          <button *ngIf="searchString !== ''" type="button" (click)="resetSearch()" class="button secondary">Clear</button>
        </div>
    </div>        
  </div>
</form>
<ng-container *ngIf="users && users.length > 0">
  <div class="button-group upper-table-actions">
    <a class="button small" (click)="exportToCsv()">Export to CSV</a>
    <div class="number-of-results">
      <span class="number-of-results__label">Results found:</span>
      <span class="number-of-results__number">{{this.searchResultsTotal}}</span>
    </div>
  </div>
  
  <table class="user-table">
    <thead>
      <tr>
        <th>
          <postd-table-sorter
            [title]="'Display Name'"
            [sortProp]="sorters[0]"
            [dropdownPosition]="{left: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th>
        <th>
          <postd-table-sorter
            [title]="'Email'"
            [sortProp]="sorters[1]"
            [dropdownPosition]="{left: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th>
        <th>Preferences</th>
        <th>UserId</th>
        <th>
          <postd-table-sorter
            [title]="'Created'"
            [sortProp]="sorters[2]"
            [dropdownPosition]="{right: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of users" [routerLink]="['/users', u.id, 'view']">
        <td>{{ u.displayName && u.displayName !== ' ' ? u.displayName : 'empty' }}</td>
        <td>{{ u.email1 }}</td>
        <td class="preferences">
          <span class="label" *ngIf="u.preferences.emailNotifications">E-mail Notifications</span>
          <span class="label" *ngIf="u.preferences.notifyPostComments">Notify Posts Comments</span>
          <span class="label" *ngIf="u.preferences.metricUnits">Metric units</span>
        </td>
        <td>{{ u.id }}</td>
        <td>{{ u.created | date: 'short'}}</td>
      </tr>
    </tbody>
  </table>
  <postd-pagination
    *ngIf="searchResultsTotal > 0"
    [items]="searchResultsTotal || 0"
    (pageChanged)="paginationPageChanged($event)"
    [itemsPerPage]="usersPerPage">
  </postd-pagination>
</ng-container>
<div *ngIf="!loading && users && users.length === 0" data-alert class="alert-box info radius">
  No users found
</div>
<postd-spinner [show]="loading"></postd-spinner>
