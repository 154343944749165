import { DragDropModule } from '@angular/cdk/drag-drop';
import { LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircle as faCircleOutline } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faStar as faStarO } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faUserFriends } from '@fortawesome/pro-regular-svg-icons/faUserFriends';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faCloud } from '@fortawesome/pro-solid-svg-icons/faCloud';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faFilm } from '@fortawesome/pro-solid-svg-icons/faFilm';
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faFlagCheckered } from '@fortawesome/pro-solid-svg-icons/faFlagCheckered';
import { faHeadphones } from '@fortawesome/pro-solid-svg-icons/faHeadphones';
import { faIndent } from '@fortawesome/pro-solid-svg-icons/faIndent';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons/faMoneyBill';
import { faMoneyBillWaveAlt } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWaveAlt';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import * as vex from 'vex-js';

import { ApiInterceptor } from '@shared/interceptors/api.interceptor';
import { AnalyticsService } from '@shared/services/analytics_service';
import { CacheService } from '@shared/services/cache_service';
import { ClassificationCategoryService } from '@shared/services/classification-category_service';
import { PerplayErrorHandler } from '@shared/services/error-handler_service';
import { LocationService } from '@shared/services/location_service';
import { LoggingService } from '@shared/services/logging_service';
import { PlaylistService } from '@shared/services/playlist_service';
import { PostService } from '@shared/services/post_service';
import { PurchaseService } from '@shared/services/purchase_service';
import { TrackingService } from '@shared/services/tracking_service';

import { SharedPipesModule } from '../app/common/shared-pipes.module';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { BackofficeAuthGuard } from './backoffice.guard';
import { ChannelsModule } from './channels/channels.module';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PaymentsModule } from './payments/payments.module';
import { PayoutsModule } from './payouts/payouts.module';
import { PostsModule } from './posts/posts.module';
import { AppRootComponent } from './root.component';
import { ActionQueueService } from './services/action-queue.service';
import { BankService } from './services/bank.service';
import { ChannelService } from './services/channel.service';
import { UserService } from './services/user.service';
import { UsersModule } from './users/users.module';
import { PaymentsService } from './services/payments.service';

@NgModule({
  declarations: [AppComponent, AppRootComponent],
  imports: [
    BrowserModule,
    FormsModule,
    FontAwesomeModule,
    SharedPipesModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    DragDropModule,
    CoreModule,
    UsersModule,
    PostsModule,
    ChannelsModule,
    DashboardModule,
    PayoutsModule,
    PaymentsModule,
  ],
  providers: [
    LoggingService,
    {
      provide: 'sessionStorage',
      useFactory: getSessionStorage,
    },
    {
      provide: 'ORIGIN_URL',
      useFactory: getOriginUrl,
    },
    {
      provide: 'vex',
      useFactory: getVexInstance,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      /* Multi is important or you will delete all the other interceptors */
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: PerplayErrorHandler,
      deps: [LocationStrategy, LoggingService],
    },
    PurchaseService,
    LocationService,
    PostService,
    PlaylistService,
    ActionQueueService,
    ChannelService,
    ClassificationCategoryService,
    UserService,
    TrackingService,
    CacheService,
    BankService,
    AnalyticsService,
    PaymentsService,
    BackofficeAuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faSearch,
      faCog,
      faTrashAlt,
      faCloud,
      faCloudUpload,
      faCheck,
      faTimes,
      faPlus,
      faFlag,
      faExclamationTriangle,
      faPencil,
      faCircleNotch,
      faSort,
      faSortDown,
      faSortUp,
      faInfoCircle,
      faQuestionCircle,
      faCheckCircle,
      faExclamationCircle,
      faShare,
      faBars,
      faUser,
      faFilm,
      faHeadphones,
      faList,
      faListOl,
      faCaretDown,
      faCaretUp,
      faAngleLeft,
      faAngleRight,
      faAngleDown,
      faChevronDown,
      faChevronLeft,
      faPlay,
      faComment,
      faStar,
      faStarHalf,
      faLink,
      faExternalLink,
      faArrowUp,
      faArrowDown,
      faIndent,
      faInfo,
      faCircle,
      faFlagCheckered,
      faMoneyBill,
      faMoneyBillWaveAlt,
      faCircleOutline,
      faUserFriends,
      faEllipsisV,
      faArrowLeft,
      faStarO
    );
  }
}

export function getOriginUrl() {
  console.log('app module location', location);
  return location.origin;
}

export function getSessionStorage() {
  return window.sessionStorage;
}

export function getVexInstance() {
  return vex;
}
