import { Location } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, forkJoin, lastValueFrom } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';

import { environment } from '@env/environment.test';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { Bank, TransferMethod } from '@shared/types/bank';
import { Channel } from '@shared/types/channel';
import { Comment } from '@shared/types/comment';
import { PaymentState } from '@shared/types/payment';
import { Payout, PayoutDestination, PayoutStatus } from '@shared/types/payout';
import { Review } from '@shared/types/review';
import { User } from '@shared/types/user';

import { ActionQueueService } from './../../services/action-queue.service';
import { BankService } from './../../services/bank.service';
import { ChannelService } from './../../services/channel.service';
import { PostService } from './../../services/post.service';
import { UserService } from './../../services/user.service';

interface UserSalesDisplay {
  channelName: string;
  postName: string;
  channelId: string;
  postId: string;
  price: number;
  earnings: number;
  commission: number;
  ipAddress: string;
  buyerId: number;
  buyerLabel: string;
  state: string;
  date: Date;
}

const USER_SALES_PER_PAGE = 50;

@Component({
  selector: 'postd-bo2-users-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class UserViewComponent implements OnDestroy {
  userId = 0;
  user?: User;
  payouts: Payout[] = [];
  displayPayoutDetail: Payout;
  userChannels: Channel[] = [];
  reviews: (Review & { selected: boolean })[] = [];
  comments: (Comment & { selected: boolean })[] = [];
  pageSections: Record<string, boolean> = {};
  taxDocumentUrl: string;
  postdURLBase = environment.apiServer;
  userSales: Array<UserSalesDisplay> = [];
  userBankingInfo: Array<Bank & { transferMethodString: string }> = [];
  userSalesPage = 0;
  loadingSales = true;

  PayoutStatus = PayoutStatus;
  PayoutDestination = PayoutDestination;

  get selectAllComments() {
    return !this.comments.find((comment) => !comment.selected);
  }
  set selectAllComments(select) {
    if (this.comments) {
      this.comments.forEach((comment) => {
        comment.selected = select;
      });
    }
  }

  get selectedComments() {
    return this.comments.filter((comment) => comment.selected);
  }

  get selectAllReviews() {
    return !this.reviews.find((rating) => !rating.selected);
  }
  set selectAllReviews(select) {
    if (this.reviews) {
      this.reviews.forEach((rating) => {
        rating.selected = select;
      });
    }
  }

  get selectedReviews() {
    return this.reviews.filter((rating) => rating.selected);
  }

  @ViewChild('deleteUserModal', { read: ModalDirective }) deleteUserModal: ModalDirective;
  @ViewChild('payoutDetailsModal', { read: ModalDirective }) payoutDetailsModal: ModalDirective;
  destroy = new Subject<void>();

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private actionQueue: ActionQueueService,
    private userService: UserService,
    private postService: PostService,
    private channelService: ChannelService,
    private bankService: BankService
  ) {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params.userId) {
        this.userId = params.userId;

        this.userService.getUser(params.userId).subscribe((resp) => {
          this.user = new User(resp);
        });

        this.channelService
          .getUserChannels(params.userId)
          .pipe(take(1))
          .subscribe((userChannels) => {
            this.userChannels = userChannels;
          });

        this.bankService
          .getUserPayouts(params.userId)
          .pipe(take(1))
          .subscribe((payouts) => {
            this.payouts = payouts;
          });

        this.userService
          .getUserComments(params.userId)
          .pipe(take(1))
          .subscribe((comments) => {
            this.comments = comments.map((comment) => ({
              ...comment,
              selected: false,
            }));
          });

        this.userService
          .getUserReviews(params.userId)
          .pipe(take(1))
          .subscribe((reviews) => {
            this.reviews = reviews.map((rating) => ({
              ...rating,
              selected: false,
            }));
          });

        this.loadUserSales();
        this.loadUserBanking();

        this.taxDocumentUrl = '/api/user/' + params.userId + '/profile/legal/tax_document';
      } else {
        this.user = undefined;
      }
    });

    this.route.fragment.subscribe((fragment) => {
      switch (fragment) {
        case 'channels':
          this.pageSections.channels = true;
          break;
        case 'payouts':
          this.pageSections.payouts = true;
          break;
        case 'comments':
          this.pageSections.comments = true;
          break;
        case 'sales':
          this.pageSections.sales = true;
          break;
        case 'banking':
          this.pageSections.banking = true;
          break;
      }
    });
  }

  ngOnDestroy() {
    this.deleteUserModal.close();
    this.payoutDetailsModal.close();
    this.destroy.next();
  }

  loadUserSales() {
    this.loadingSales = true;
    lastValueFrom(
      this.userService.getUserSales(this.userId, this.userSalesPage, USER_SALES_PER_PAGE).pipe(
        take(1),
        map((userSales) =>
          userSales.map((userSale) => ({
            channelName: userSale.channel?.name,
            channelId: userSale.channel?.id,
            postName: userSale.post?.name,
            postId: userSale.post?.id,
            price: userSale.price,
            earnings: userSale.usrEarns_cc,
            commission: userSale.commission_cc,
            ipAddress: userSale.ip_adress,
            buyerId: userSale.buyerId,
            buyerLabel:
              userSale.buyer?.displayName ?? 'Unknown User ' + userSale.buyerId.toString(),
            state: PaymentState[userSale.state],
            date: new Date(userSale.created),
          }))
        )
      )
    ).then((userSales) => {
      this.userSales.push(...userSales);
      this.loadingSales = false;
    });

    this.userSalesPage++;
  }

  loadUserBanking() {
    lastValueFrom(this.bankService.getUserBankingInfo(this.userId)).then((bankingInfo) => {
      this.userBankingInfo = bankingInfo
        // Filter out banks that don't have any transfer method information
        .filter((bank) => bank.iban || bank.swift || bank.routingNumber || bank.accountNumber)
        .map((bank) => ({
          ...bank,
          transferMethodString: TransferMethod[bank.transferMethod],
        }));
    });
  }

  confirmDeleteUser() {
    this.deleteUserModal.open();
  }

  deleteUser() {
    lastValueFrom(this.userService.deleteUser(this.user.id)).then(
      () => {
        this.deleteUserModal.close();
        this.router.navigate(['/users']);
      },
      () => {
        console.error(`could not delete user ${this.user.id}`);
      }
    );
  }

  deleteComments(commentsToDelete: Comment[]) {
    const originalComments = this.comments;
    commentsToDelete.forEach((selectedComment) => {
      this.comments = this.comments.filter((comment) => comment !== selectedComment);
    });

    const deleteObservables = commentsToDelete.map((comment) =>
      this.postService.deleteComment(comment.postId, comment.id)
    );
    this.actionQueue
      .addAction(
        `Deleted ${commentsToDelete.length} comment${commentsToDelete.length > 1 ? 's' : ''}`,
        forkJoin(...deleteObservables),
        this.destroy
      )
      .pipe(catchError(() => (this.comments = originalComments)))
      .subscribe();
  }

  deleteReviews(reviewsToDelete: Review[]) {
    const originalReviews = this.reviews;
    reviewsToDelete.forEach((selectedReview) => {
      this.reviews = this.reviews.filter((review) => review !== selectedReview);
    });

    const deleteObservables = reviewsToDelete.map((review) =>
      this.postService.deleteComment(review.postId, review.id)
    );
    this.actionQueue
      .addAction(
        `Deleted ${reviewsToDelete.length} review${reviewsToDelete.length > 1 ? 's' : ''}`,
        forkJoin(...deleteObservables),
        this.destroy
      )
      .pipe(catchError(() => (this.reviews = originalReviews)))
      .subscribe();
  }

  showPayoutDetails(payout: Payout) {
    this.displayPayoutDetail = payout;
    this.payoutDetailsModal.open();
  }

  back() {
    this.location.back();
  }
}
