
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PostdSpinnerModule } from '@shared/components/postd-spinner/postd-spinner.module';

import { SharedDirectivesModule } from './../../app/common/directives.module';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { PayoutsListComponent } from './list/list.component';
import { PayoutsViewComponent } from './view/view.component';


@NgModule({
  declarations: [ PayoutsListComponent, PayoutsViewComponent ],
  exports: [ PayoutsListComponent, PayoutsViewComponent ],
  imports: [
    CommonModule,
    PostdSpinnerModule,
    DropdownModule,
    SharedDirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule
  ]
})
export class PayoutsModule { }
