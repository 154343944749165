import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DropdownComponent } from './dropdown.component';
import { CloseOnClickDirective } from './close-on-click.directive';

@NgModule({
  imports:      [ CommonModule, FontAwesomeModule ],
  declarations: [ DropdownComponent, CloseOnClickDirective ],
  exports:      [ DropdownComponent, CloseOnClickDirective ]
})

export class DropdownModule { }
