import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PurchaseService } from '@shared/services/purchase_service';
import { LocationService } from '@shared/services/location_service';
import { PlaylistService as ClientPlaylistService } from '@shared/services/playlist_service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService extends ClientPlaylistService {
  constructor(
    httpClient: HttpClient,
    locationService: LocationService,
    purchaseService: PurchaseService
  ) {
    super(
      httpClient,
      locationService,
      purchaseService
    );
  }
}
