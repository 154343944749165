
import { Injectable } from '@angular/core';
import { Observable, timer, merge, throwError, Subject, BehaviorSubject, NEVER, empty } from 'rxjs';
import { switchMap, take, map, tap, share, publish, finalize } from 'rxjs/operators';

const UNDO_TIME_LIMIT = 10000; // length of time the user can undo in ms
const CANCEL: 'CANCEL' = 'CANCEL';

export interface Action {
  message: string;
  cancel: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class ActionQueueService {
  private actionList: Action[] = [];

  latestAction = new BehaviorSubject<Action | undefined>(undefined);

  constructor() {}

  /**
   * Add an undo-able action to the action queue
   *
   * @param message The descriptive text message to display for this action
   * @param action The cold observable that will be executed. Typically an http request.
   * @param commitSubject A one-time observable that will cause the action to execure right away.
   * @param cancelSubject A one-time observable that will cause the action to be cancelled.
   */
  addAction<T>(
    message: string,
    action: Observable<T>,
    commitSubject?: Observable<void>,
    cancelSubject?: Observable<void>
  ) {
    const undoSubject = new Subject<void>();

    return merge(
      empty().pipe(finalize(() => {
        // There's nothing in the queue until the timer is actually in flight
        this.actionList.push({
          message,
          cancel: () => undoSubject.next()
        });
        this.updateLatestAction();
      })),
      timer(UNDO_TIME_LIMIT),
      undoSubject.pipe(map(() => CANCEL)),
      commitSubject ? commitSubject.pipe<null>(map(() => null)) : NEVER,
      cancelSubject ? cancelSubject.pipe(map(() => CANCEL)) : NEVER
    )
      .pipe(
        take(1),
        tap(() => this.actionList.pop() && this.updateLatestAction()),
        switchMap(result => {
          if (result === CANCEL) {
            return throwError('Cancelled');
          }
          return action;
        })
      );
  }

  private updateLatestAction() {
    this.latestAction.next(
      this.actionList.length > 0 ? this.actionList[this.actionList.length - 1] : undefined
    );
  }
}
