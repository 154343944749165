import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, forkJoin, of, from, Observable } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';

import { Post } from '@shared/types/post';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { FlagReason, FlagAction } from '@shared/types/flagging';
import { PostService, IFlaggedPost } from './../../services/post.service';

export enum FlaggedPostsTypes {
  invalid, valid
}

@Component({
  selector: 'postd-bo2-post-flagged',
  templateUrl: './flagged.component.html',
  styleUrls: ['./flagged.component.scss']
})
export class PostsFlaggedComponent implements OnDestroy {
  postList: (Post & { flagInfo: IFlaggedPost})[] = [];
  pageMode: FlaggedPostsTypes;
  loading = true;

  FlagReason = FlagReason;
  FlagAction = FlagAction;
  FlaggedPostsTypes = FlaggedPostsTypes;

  destroy = new Subject<void>();

  @ViewChild('postDetailModal', { read: ModalDirective }) postDetailModal: ModalDirective;
  postDetailModalScope: {
    postDetail: Observable<Post>;
    flagInfo: IFlaggedPost;
    flagResolved: boolean;
    setActionTaken?: FlagAction;
    error?: number;
  };

  constructor(
    private route: ActivatedRoute,
    private postService: PostService
  ) {
    route.data.subscribe(data => {
      this.pageMode = data.type;
      this.init();
    });
  }

  init() {
    this.loading = true;
    this.postList = [];
    let postsFetchObservable: Observable<IFlaggedPost[]>;
    if (this.pageMode === FlaggedPostsTypes.invalid) {
      postsFetchObservable = this.postService.getFlaggedInvalidPosts();
    } else if (this.pageMode === FlaggedPostsTypes.valid) {
      postsFetchObservable = this.postService.getFlaggedValidPosts();
    } else {
      postsFetchObservable = this.postService.getFlaggedPosts();
    }

    postsFetchObservable
      .pipe(
        mergeMap(flaggedPosts => {
          if (flaggedPosts.length === 0) {
            return of([]);
          }
          return forkJoin(
            flaggedPosts.map(flag =>
              this.postService.getPostInfo(flag.postId).pipe(
                catchError(() => {
                  console.warn(`Post ${flag.postId} was flagged, but the post was not found.`);
                  return of(undefined);
                })
              )
            )
          ).pipe(
            map((posts: (Post | undefined)[]) => posts
                .filter(post => post !== undefined)
                .map((_post, index) => {
                  const post = _post as (Post & { flagInfo: IFlaggedPost});
                  post.flagInfo = flaggedPosts[index];
                  return post;
                }))
          );
        }),
        tap(() => this.loading = false)
      ).subscribe((posts) => {
        this.postList.push(...posts);
      });
  }

  ngOnDestroy() {
    this.postDetailModal.close();
    this.destroy.next();
  }

  openPostDetail(postInfo: Post & { flagInfo: IFlaggedPost}) {
    this.postDetailModalScope = {
      postDetail: from(this.postService.fetchPost(postInfo.id)),
      flagInfo: postInfo.flagInfo,
      flagResolved: false
    };
    if (this.pageMode !== undefined) {
      this.postDetailModalScope.flagResolved = true;
      this.postDetailModalScope.setActionTaken =
        postInfo.flagInfo.actionTaken !== FlagAction.None ? postInfo.flagInfo.actionTaken : FlagAction.FlagRejected;
    }

    this.postDetailModal.open({}, this.postDetailModalScope);
  }

  resolveFlag(flag: IFlaggedPost, action: FlagAction) {
    switch(action) {
      case FlagAction.PostTakedown:
        this.postService.acceptPostFlag(flag.id).subscribe(() => {
          this.postDetailModal.close();
          this.init();
        }, err => {
          // 409 is already flagged
          this.postDetailModalScope.error = err.status;
        });
        break;
      case FlagAction.None:
        console.log('None');
        break;
      case FlagAction.FlagRejected:
        this.postService.rejectPostFlag(flag.id).subscribe(() => {
          console.log('success reject post flag');
          this.postDetailModal.close();
          this.init();
        }, err => {
          this.postDetailModalScope.error = err.status;
        });
        break;
    }
  }

}
