// Expose debugsApp in window object in order to access it from the console.
// window.debug is already used by chrome.
import * as debug from 'debug';
(window as any).debugApp = debug; // The typescript way to extend window

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'postd-backoffice',
  templateUrl: './app.component.html',
  styleUrls: ['../app/frontend/core/scss/main.scss', './app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {}
