<section class="dashboard">
    <h1 class="dashboard__headline">Dashboard</h1>
    <div class="dashboard__wrapper">
        <a [routerLink]="['/users']" class="dashboard__wrapper__box">
            <h2 class="dashboard__wrapper__box__title">
                Users
            </h2>
            <span
                *ngIf="!stats.users.loading"
                class="dashboard__wrapper__box__counter">
                {{stats.users.counter}}
            </span>
            <span class="dashboard__wrapper__box__icon">
                <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z"></path></svg>
            </span>
            <span
                *ngIf="stats.users.loading"
                class="dashboard__wrapper__box__loader">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></g></svg>
            </span>
        </a>
        <a [routerLink]="['/channels']" class="dashboard__wrapper__box">
            <h2 class="dashboard__wrapper__box__title">
                Channels
            </h2>
            <span
                *ngIf="!stats.channels.loading"
                class="dashboard__wrapper__box__counter">
                {{stats.channels.counter}}
            </span>
            <span class="dashboard__wrapper__box__icon">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19,6H14.41l2.3-2.29a1,1,0,1,0-1.42-1.42L12,5.59,8.71,2.29A1,1,0,1,0,7.29,3.71L9.59,6H5A3,3,0,0,0,2,9V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V9A3,3,0,0,0,19,6ZM16,16a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V10A2,2,0,0,1,6,8h8a2,2,0,0,1,2,2Zm3,3a1,1,0,1,1,1-1A1,1,0,0,1,19,19Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,19,15Zm0-4a1,1,0,1,1,1-1A1,1,0,0,1,19,11Z" fill="#464646"/></svg>
            </span>
            <span
                *ngIf="stats.channels.loading"
                class="dashboard__wrapper__box__loader">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></g></svg>
            </span>
        </a>
        <a [routerLink]="['/posts']" class="dashboard__wrapper__box">
            <h2 class="dashboard__wrapper__box__title">
                Posts
            </h2>
            <span
                *ngIf="!stats.posts.loading"
                class="dashboard__wrapper__box__counter">
                {{stats.posts.counter}}
            </span>
            <span class="dashboard__wrapper__box__icon">
                <svg role="img" aria-hidden="true" focusable="false" data-prefix="far" data-icon="note-sticky" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M384 32H64.01C28.66 32 .0085 60.65 .0065 96L0 415.1C-.002 451.3 28.65 480 64 480h232.1c25.46 0 49.88-10.12 67.89-28.12l55.88-55.89C437.9 377.1 448 353.6 448 328.1V96C448 60.8 419.2 32 384 32zM52.69 427.3C50.94 425.6 48 421.8 48 416l.0195-319.1C48.02 87.18 55.2 80 64.02 80H384c8.674 0 16 7.328 16 16v192h-88C281.1 288 256 313.1 256 344v88H64C58.23 432 54.44 429.1 52.69 427.3zM330.1 417.9C322.9 425.1 313.8 429.6 304 431.2V344c0-4.406 3.594-8 8-8h87.23c-1.617 9.812-6.115 18.88-13.29 26.05L330.1 417.9z"></path></svg>
            </span>
            <span
                *ngIf="stats.posts.loading"
                class="dashboard__wrapper__box__loader">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></g></svg>
            </span>
        </a>
    </div>
</section>