import { faTombstoneAlt } from './../../../vendor/@fortawesome/pro-regular-svg-icons/faTombstoneAlt.d';
import { UserService } from './../services/user.service';
import { ChannelService } from './../services/channel.service';
import { PostService } from './../services/post.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'postd-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public stats = {
    users: {
      counter: 0,
      loading: true
    },
    channels: {
      counter: 0,
      loading: true
    },
    posts: {
      counter: 0,
      loading: true
    }
  };

  constructor(
    private postService: PostService,
    private userService: UserService,
    private channelService: ChannelService
  ) {}

  public ngOnInit(): void {
    this.getDashboardData();
  }

  private getDashboardData(): void {
    this.userService.getUsers().subscribe(users => {
      if (users && users.total) {
        this.stats.users.counter = users.total;
        this.stats.users.loading = false;
      }
    });

    const postsQuery = {
      query: ''
    };

    this.postService.queryPosts(postsQuery).then(res => {
      if (res && res.length) {
        this.stats.posts.counter = res.length;
        this.stats.posts.loading = false;
      }
    });

    this.channelService.getChannels({}).then(res => {
      if (res && res.channels) {
        this.stats.channels.counter = res.channels.length;
        this.stats.channels.loading = false;
      }
    });
  }
}
