<h3>Browse Posts</h3>

<form>
  <div class="grid-x">
    <div class="input-group cell medium-12">
      <input [formControl]="searchParam" name="searchParam" class="input-group-field" type="text" placeholder="Search Parameter">
      <div class="input-group-button">
        <button id="search-button" (click)="search()" type="submit" class="button primary">Search</button>
        <button *ngIf="searchString !== ''" type="button" (click)="resetSearch()" class="button secondary">Clear</button>
      </div>
    </div>
  </div>
</form>
<ng-container *ngIf="postList && postList.length > 0">
  <table
    class="posts-table"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="200"
    [infiniteScrollDisabled]="endOfResults"
    (scrolled)="loadPosts()">
    <thead>
      <tr>
        <th>Name</th>
        <th>Channel</th>
        <th>User Display Name</th>
        <th>
          <postd-table-sorter
            [title]="'Created'"
            [sortProp]="sorters[0]"
            [dropdownPosition]="{left: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th>
        <th>
          <postd-table-sorter
            [title]="'Updated'"
            [sortProp]="sorters[1]"
            [dropdownPosition]="{right: true}"
            [showArrows]="true"
            [resetSorter]="resetSorters"
            (sortingTypeSelected)="sortingChanged($event)">
          </postd-table-sorter>
        </th>
        <th>State</th>
        <th>Tools</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of postList">
        <td><a (click)="openPostDetail(p)">{{ p.name }}</a></td>
        <td><a [routerLink]="['/channels', p.channelId, 'view']">{{ p.channelName }}</a></td>
        <td><a [routerLink]="['/users', p.userId, 'view']">{{ p.userDisplayName }}</a></td>
        <td>{{ p.created | date: 'short'}}</td>
        <td>{{ p.updated | date: 'short'}}</td>
        <td>{{ p.state }}</td>
        <td>
          <a href="{{ p.fullExternalURL }}" target="_blank">
            <fa-icon [icon]="['fas', 'external-link']" aria-hidden="true"></fa-icon>
          </a>
          &nbsp;
          <a (click)="openPostDetail(p)">
            <fa-icon [icon]="['fas', 'info']" aria-hidden="true"></fa-icon>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<div *ngIf="!loading && postList && postList.length === 0" data-alert class="alert-box info radius">
  No posts found
</div>
<ng-template
  #postDetailModal
  [postdModalTemplate]="{ 'contentClassName': 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-post-view *ngIf="data.postDetail" [post]="data.postDetail"></postd-bo2-post-view>
</ng-template>
<postd-spinner [show]="loading"></postd-spinner>

