import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PostdSpinnerModule } from '@shared/components/postd-spinner/postd-spinner.module';

import { SharedDirectivesModule } from './../../app/common/directives.module';
import { PostsSearchComponent } from './search/search.component';
import { PostsFlaggedComponent } from './flagged/flagged.component';
import { PostsViewComponent } from './view/view.component';
import { PickerComponent } from './picker/picker.component';
import { PostsFeaturedComponent } from './featured/featured.component';
import { TableSorterModule } from '@shared/components/table-sorter/table-sorter.module';

@NgModule({
  declarations: [
    PostsSearchComponent,
    PostsFlaggedComponent,
    PostsViewComponent,
    PickerComponent,
    PostsFeaturedComponent
  ],
  exports: [
    PostsSearchComponent,
    PostsFlaggedComponent,
    PostsViewComponent,
    PickerComponent,
    PostsFeaturedComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    DragDropModule,
    InfiniteScrollModule,
    PostdSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SharedDirectivesModule,
    TableSorterModule
  ]
})
export class PostsModule { }
