import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'postd-table-sorter',
  templateUrl: './table-sorter.component.html',
  styleUrls: ['./table-sorter.component.scss'],
})
export class TableSorterComponent implements OnChanges {
  @Input() title: string;
  @Input() sortProp: string;
  @Input() showArrows: boolean;
  @Input() resetSorter: string[];
  @Input() dropdownPosition: {
    left?: boolean;
    right?: boolean;
  };

  @Output() public sortingTypeSelected: EventEmitter<{ sorter: string; sortType: string }> =
    new EventEmitter();

  public orderTypes = [
    {
      label: 'Sort Ascending',
      value: 'asc',
    },
    {
      label: 'Sort Descending',
      value: 'desc',
    },
    {
      label: 'Default sorting',
      value: 'default',
    },
  ];

  public showDropdown = false;
  public currentOrder: string;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetSorter && changes.resetSorter.currentValue) {
      const sortOrders = changes.resetSorter.currentValue;

      if (sortOrders && sortOrders.includes(this.sortProp)) {
        this.resetOrderSorting();
      }
    }
  }

  public setSortType(type: string): void {
    this.hideDropdown();
    this.currentOrder = type === 'asc' || type === 'desc' ? type : '';
    this.sortingTypeSelected.emit({
      sorter: this.sortProp || this.title,
      sortType: type,
    });
  }

  public showSortingDropdown(): void {
    if (!this.showDropdown) {
      this.showDropdown = true;
    }
  }

  public hideDropdown(): void {
    this.showDropdown = false;
  }

  private resetOrderSorting(): void {
    this.currentOrder = '';
    this.showDropdown = false;
  }
}
