import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'postd-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() items: number;
  @Input() itemsPerPage: number;
  @Input() showArrows: boolean;
  @Input() showArrowLabels: boolean;

  public currentPage = 0;
  public paginationObject: { page: number; label: number; selected: boolean }[] = [];

  @Output() public pageChanged: EventEmitter<number> = new EventEmitter();

  public ngOnInit(): void {
    this.preparePagination();
  }

  public changePage(direction: string): void {
    const currentSelected: any = this.getCurrentSelectedPage();

    if (direction === 'left') {
      if (currentSelected.page > 0) {
        this.setPage(this.paginationObject[currentSelected.page - 1]);
      }
    } else {
      if (currentSelected.page < this.paginationObject.length - 1) {
        this.setPage(this.paginationObject[currentSelected.page + 1]);
      }
    }
  }

  public setPage(page: { page: number; label: number; selected: boolean }): void {
    for (const p of this.paginationObject) {
      if (page.page === p.page) {
        p.selected = true;
      } else {
        p.selected = false;
      }
    }

    this.sendEvent(page.page);
  }

  private preparePagination(): void {
    const pages = this.items / this.itemsPerPage;

    for (let i = 0; i < Math.ceil(pages); i++) {
      this.paginationObject.push({
        page: i,
        label: i + 1,
        selected: this.currentPage === i,
      });
    }
  }

  private getCurrentSelectedPage() {
    return this.paginationObject.filter((p) => p.selected)[0] || 0;
  }

  private sendEvent(page: number): void {
    this.pageChanged.emit(page);
  }
}
