import * as Debug from 'debug';
const debug = Debug('shared:PostdSpinnerComponent');

import { Component, Input } from '@angular/core';

@Component ({
    selector: 'postd-spinner',
    templateUrl: './postd-spinner.component.html',
    styleUrls: ['./postd-spinner.component.scss']
})
export class PostdSpinnerComponent {

    @Input() show: boolean;
    @Input() fullPage = true;

    constructor() {
    }

}
