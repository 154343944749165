import * as Debug from 'debug';
const debug = Debug('perplay:HeaderComponent');

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { User } from '@shared/types/user';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'postd-bo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('signInModal', { read: ModalDirective }) signInModal: ModalDirective;

  curUser: User;
  curUserSubscription: Subscription;
  routeSubscription: Subscription;

  searchInput: string;
  showLeftNav: boolean;
  showMobileSearchBar = false;

  constructor(
      private userService: UserService,
      private router: Router,
      private route: ActivatedRoute) {
    this.searchInput = '';
    this.showLeftNav = false;

    this.curUserSubscription = this.userService.curUser$
       .subscribe(user => {
        debug('subscribed to user', user);
         this.curUser = user;
        }, error => {
         console.log('error in subscribing to user', error);
       });

    this.routeSubscription = this.route.queryParams.subscribe(queryParams => {
      if (queryParams['q'] && queryParams['q'] !== '' && this.route.snapshot.url[0].path === 'search') {
        this.searchInput = queryParams['q'];
      }
    });
  }

  ngOnInit() {
    this.userService.getProfile().then((user) => {
      this.curUser = user;
      debug('current user', this.curUser);
    });

    this.userService.leftNavState$
      .subscribe((state) => {
        if (state !== undefined) {
          this.showLeftNav = state.open;
        }
      });
  }

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event'])
  closeMobileSearchBar(event) {
    if (event.target.classList.contains('mobile-search-bar')) {
      this.closeMobileBar();
    }
  }

  onSigninClick() {
    this.signInModal.open();
  }

  isUserLoggedIn() {
    return this.userService.isLoggedIn();
  }

  loginWithTransact() {
    this.userService.login();
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/']);
  }

  toggleLeftNav() {
    this.showLeftNav = !this.showLeftNav;
    this.userService.updateLeftNav({
      open: this.showLeftNav
    });
  }

  // Search functions

  onSearch(evt: Event) {
    this.router.navigate(['/search'], { queryParams: { q: this.searchInput } });
    debug('onSearch', evt);
    if (this.showMobileSearchBar) {
      this.closeMobileBar();
    }
  }

  enterKeyPressed(evt: Event) {
    debug('enterKeyPressed', evt);
    this.onSearch(evt);
  }

  showSearchBar() {
    this.showMobileSearchBar = true;
  }

  closeMobileBar() {
    this.showMobileSearchBar = false;
  }
}
