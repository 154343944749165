import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PostService } from './../services/post.service';
import { Post } from '@shared/types/post';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PostResolver  {
  constructor(private service: PostService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Post> | Promise<Post> | Post {
    return this.service.fetchPost(route.paramMap.get('postId'));
  }
}
