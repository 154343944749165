export enum FlagReason {
    // constant members
    None = 0,
    Offensive  = 1 << 1,
    Fraud      = 1 << 2,
    Abuse      =  1 << 3,
    Malware    =  1 << 4,
    Copyright  =  1 << 5,
    Pornographic  =  1 << 6,
    SPAM  =  1 << 7,
    HateSpeech  =  1 << 8
}

export enum FlagAction {
    None            = 0,
    PostTakedown    = 1 << 1,
    PublisherBanned = 1 << 2,
    FundsSeized     = 1 << 3,
    CommentRemoved  = 1 << 4,
    FlagRejected    = -1 // Client only
}
