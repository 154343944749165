// Pull the pipes from Common to
// reuse in the backoffice app without dragging in all of the common elements

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostdSpinnerComponent } from './postd-spinner.component';

@NgModule({
    declarations: [
        PostdSpinnerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PostdSpinnerComponent
    ]
})
export class PostdSpinnerModule {
}
