import { Address } from './address';

// export type entityType = 'INDIVIDUAL' | 'COMPANY';

export enum TransferMethod {
  // constant members
  Invalid = 0,
  ACH = 1, // US Only
  IBAN = 2, //
  SWIFT = 3, //
  CHECK = 4, //
}


export class Bank {
  public id: string;
  public userId: number; // user id who created this entry
  public teamId: string; // If for a team, their ID
  public name: string; // name of bank
  public accountHolder: string; // account holders name
  public address: Address;
  public transferMethod: TransferMethod;

  public iban: string; // set if IBAN, else unset
  public swift: string; // set if SWIFT, else unset
  public routingNumber: string; // ABA routing number for ACH
  public accountNumber?: string; // Account number for ACH or other
  public accNumLast4?: string; // Account number for ACH or other

  public created: number;
  public updated: number;
}
