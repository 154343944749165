import { Playlist } from '@shared/types/playlist';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, from, forkJoin, of } from 'rxjs';
import { takeUntil, switchMap, map, mergeMap, tap, catchError } from 'rxjs/operators';
import { User } from '@shared/types/user';
import { Channel } from '@shared/types/channel';
import { Post } from '@shared/types/post';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { PlaylistService } from '@shared/services/playlist_service';
import { environment } from '@env/environment';

import { UserService } from './../../services/user.service';
import { ChannelService } from './../../services/channel.service';
import { ActionQueueService } from './../../services/action-queue.service';
import { PostService } from './../../services/post.service';
import { Payment } from '@shared/types/payment';

interface SalesWithAttlInfo extends Payment {
  user?: Observable<User | Partial<User>>;
  postFetch?: Observable<Post>;
  transactUserURL?: string;
}
@Component({
  selector: 'postd-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ChannelViewComponent implements OnDestroy {
  channel: Observable<Channel>;
  user: Observable<User>;
  playlists: Promise<Playlist[]>;
  postList: Post[] = [];
  sales = [];
  postCount = 0;
  pageSections: boolean[] = [true];

  public featuredPostData: { loading: boolean; post: Post | null } = {
    loading: false,
    post: null,
  };

  @ViewChild('postDetailModal', { read: ModalDirective }) postDetailModal: ModalDirective;
  @ViewChild('playlistDetailModal', { read: ModalDirective }) playlistDetailModal: ModalDirective;

  destroy = new Subject<void>();

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private actionQueue: ActionQueueService,
    private userService: UserService,
    private postService: PostService,
    private channelService: ChannelService,
    private playlistService: PlaylistService
  ) {
    this.initChannelView();
  }

  ngOnDestroy() {
    this.postDetailModal.close();
    this.playlistDetailModal.close();
  }

  openPostDetail(post: Post) {
    this.postDetailModal.open(
      {},
      {
        postDetail: from(this.postService.fetchPost(post.id)),
      }
    );
  }

  openPlaylistDetail(playlist: Playlist) {
    this.playlistDetailModal.open(
      {},
      {
        playlistDetail: this.playlistService.getPlaylist(playlist.id),
      }
    );
  }

  public deleteChannel(channelId: string): void {
    this.postService
      .getChannelPosts(channelId, 0, 1000000, '', '', 'createdDateDesc')
      .then((resp) => {
        const posts = resp && resp.posts.length ? resp.posts : [];
        const postsForDelate = [];
        if (posts) {
          for (const post of posts) {
            postsForDelate.push(this.postService.deletePost(post.id));
          }

          forkJoin(postsForDelate).subscribe(
            () => {
              this.channelService.deleteChannel(channelId).subscribe((res) => {
                this.location.back();
              });
            },
            (err) => {
              console.log('Error while deleting posts', err);
            }
          );
        }
      });
  }

  public postFeatureToggle(post: Post): void {
    this.featuredPostData = {
      loading: true,
      post,
    };
    if (post.featured) {
      this.postService.removeFeaturedPost(post.id).subscribe(() => {
        this.featuredPostData = {
          loading: false,
          post: null,
        };
        post.featured = 0;
      });
    } else {
      from(this.postService.getFeaturedPosts())
        .pipe(
          mergeMap((featuredPosts) => {
            const newFeatured = featuredPosts.length + 1;
            return this.postService
              .setFeaturedPostOrder(post.id, newFeatured)
              .pipe(tap(() => (post.featured = newFeatured)));
          })
        )
        .subscribe(
          () => {
            this.featuredPostData = {
              loading: false,
              post: null,
            };
          },
          (err) => {
            post.featured = 0;
            console.error(err);
          }
        );
    }
  }

  private initChannelView(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params.channelId) {
        this.channel = this.channelService.getChannelInfo(params.channelId, true).pipe(
          tap((channel) => {
            this.user = this.userService.getUser(channel.userId);
          })
        );

        this.playlists = this.channelService.getChannelPlaylists(params.channelId);
        /*
          dateAsc
          dateDesc
          priceAsc
          priceDesc
          purchaseCountAsc
          purchaseCountsc
          ratingAsc
          ratingDesc
        */
        this.postService
          .getChannelPosts(params.channelId, 0, 20, '', '', 'dateAsc')
          .then((resp) => {
            this.postList = resp.posts;
            this.postCount = resp.total;
          });

        this.channelService.getSales(params.channelId).then((sales: SalesWithAttlInfo[]) => {
          for (const s of sales) {
            s.transactUserURL = environment.transactBaseUrl + '/bizops/user/' + s.buyerId;
            s.user = this.userService.getUser(s.buyerId).pipe(
              catchError((err) => {
                console.log(err.status);
                const errResult = { id: s.buyerId } as Partial<User>;
                if (err.status === 404) {
                  errResult.displayName = `Deleted UserID ${s.buyerId}`;
                } else {
                  errResult.displayName = `Error fetching user ${s.buyerId}`;
                }
                return of(errResult);
              })
            );
            s.postFetch = this.postService.getPostInfo(s.postId);
          }

          this.sales = sales;
        });
      } else {
        this.channel = undefined;
      }
    });
  }
}
