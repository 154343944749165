import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Post } from '@shared/types/post';
import { ModalDirective } from '@shared/directives/modal/modal.directive';

import { from } from 'rxjs';
import { PickerComponent } from '../picker/picker.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'postd-bo2-post-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class PostsSearchComponent extends PickerComponent implements OnInit, OnDestroy {
  @ViewChild('postDetailModal', { read: ModalDirective }) postDetailModal: ModalDirective;

  public resetSorters: string[];
  private sorters = ['createdDate', 'date'];

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params.query) {
        this.searchString = params.query;
      } else {
        this.searchString = '';
      }
      if (this.searchParam.value !== params.query) {
        this.searchParam.setValue(params.query);
      }

      this.reset();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.postDetailModal.close();
  }

  search() {
    this.router.navigate(['/', 'posts'], { queryParams: { query: this.searchParam.value } });
  }

  resetSearch() {
    this.searchParam.setValue('');
    this.router.navigate(['/', 'posts'], { queryParams: { query: '' } });
  }

  openPostDetail(post: Post) {
    this.postDetailModal.open(
      {},
      {
        postDetail: from(this.postService.fetchPost(post.id)),
      }
    );
  }

  public sortingChanged(ev: any): void {
    this.sortingOrder =
      ev.sorter && ev.sortType && ev.sortType !== 'default'
        ? ev.sorter + ev.sortType.replace(ev.sortType[0], ev.sortType[0].toUpperCase())
        : '';

    this.resetSorters = this.sorters.filter((s) => s !== ev.sorter);
    this.page = 0;
    this.loading = true;
    this.loadPosts(true);
  }
}
