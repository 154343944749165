import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ModalDirective } from '@shared/directives/modal/modal.directive';

import { BankService } from './../../services/bank.service';
import { UserService } from './../../services/user.service';
import { PayoutStatus, Payout } from '@shared/types/payout';
import { User } from '@shared/types/user';
import { FundsMap } from '@shared/types/funds_map';

type PayoutInfo = Payout & { user: User };

@Component({
  selector: 'postd-bo2-payouts-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class PayoutsListComponent implements OnDestroy {
  payouts: Observable<PayoutInfo[]>;
  loading = true;
  selectedPayoutStatus = PayoutStatus.Requested;
  PayoutStatus = PayoutStatus;

  statusOptions = Object.keys(PayoutStatus).reduce(
    (options, key) => {
      if (typeof PayoutStatus[key] === 'number') {
        options.push({ label: key, value: PayoutStatus[key] });
      }
      return options;
  }, []);

  @ViewChild('payoutDetailModal', { read: ModalDirective }) payoutDetailModal: ModalDirective;

  constructor(private bankService: BankService, private userService: UserService) {
    this.updatePayoutList();
  }

  setStatus(status: PayoutStatus) {
    this.selectedPayoutStatus = status;
    this.updatePayoutList();
  }

  updatePayoutList() {
    this.loading = true;
    this.payouts = this.bankService.getPayouts(this.selectedPayoutStatus).pipe(
      mergeMap((payouts) =>
        forkJoin(
          payouts.map((payout) =>
            this.userService.getUser(payout.userId).pipe(
              catchError(() =>
                of({
                  id: -1,
                  displayName: 'Deleted User ' + payout.userId,
                  email1: '',
                  funds: { PROD: 0 } as FundsMap,
                } as User)
              ),
              map((user) => ({ ...payout, user }))
            )
          )
        )
      ),
      map(payouts => payouts.sort((a, b) => b.created - a.created)),
      tap(() => (this.loading = false))
    );
  }

  ngOnDestroy() {
    this.payoutDetailModal.close();
  }

  openPayoutDetail(payout: PayoutInfo) {
    this.payoutDetailModal.open(
      {},
      {
        payoutDetail: payout,
      }
    );
  }
}
