<h3>Browse Payouts</h3>

<postd-dropdown-menu class="menu select button-style">
  <ul class="dropdown checkbox-list" postdCloseOnClick>
      <li *ngFor="let s of statusOptions">
          <div class="pretty p-default" >
              <input type="checkbox" [checked]="s.value === selectedPayoutStatus" (click)="setStatus(s.value)" />
              <div class="state p-info"><label>{{s.label}}</label></div>
          </div>
      </li>
  </ul>
  <a class="dropdown-label">
    {{ PayoutStatus[selectedPayoutStatus] }} Status
    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
  </a>
</postd-dropdown-menu>

<ng-container *ngIf="payouts | async as payoutList">
  <table *ngIf="payoutList.length > 0">
    <thead>
      <tr>
        <th>Date</th>
        <th>User Name</th>
        <th>Email</th>
        <th>Bank</th>
        <th>Amount<br/>Requested</th>
        <th>User<br/>Funds</th>
        <th>Amount<br/>Sent</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of payoutList">
        <td class="align-top"><a (click)="openPayoutDetail(p)">{{ p.created | date:'short' }}</a></td>
        <td class="align-top">{{ p.user.displayName }}</td>
        <td class="align-top">{{ p.user.email1 }}</td>
        <td class="align-top">
            <span style="white-space: pre-line" *ngIf="p.bank.routingNumber">{{ 'Routing: ' + p.bank?.routingNumber + '\n'}}</span>
            <span style="white-space: pre-line" *ngIf="p.bank.accountNumber">{{ 'Account: ' + p.bank?.accountNumber + '\n' }}</span>
            <span style="white-space: pre-line" *ngIf="p.bank.iban">{{ 'IBAN: ' + p.bank.iban  + '\n'}}</span>
            <span style="white-space: pre-line" *ngIf="p.bank.swift">{{ 'Swift: ' + p.bank.swift }}</span>
        </td>
        <td class="align-top text-center">{{ p.amountReq/100 | currency:'USD' }}</td> <!-- Need to check the units! -->
        <td class="align-top text-center">{{ p.user.funds?.PROD/100 | currency:'USD' }}</td>
        <td class="align-top text-center">{{ p.amountSent/100 | currency:'USD' }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!loading &&  payoutList.length === 0" class="alert-box info radius">
    No payouts found
  </div>
</ng-container>
<postd-spinner [show]="loading"></postd-spinner>
<ng-template
  #payoutDetailModal
  [postdModalTemplate]="{ 'contentClassName': 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-payouts-view [payout]="data.payoutDetail"></postd-bo2-payouts-view>
</ng-template>
