import { Routes } from '@angular/router';

import { BackofficeAuthGuard } from './backoffice.guard';
import { ChannelsBrowseComponent } from './channels/browse/browse.component';
import { ChannelViewComponent } from './channels/view/view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentsListComponent } from './payments/payments.component';
import { PayoutsListComponent } from './payouts/list/list.component';
import { PostsFeaturedComponent } from './posts/featured/featured.component';
import { FlaggedPostsTypes, PostsFlaggedComponent } from './posts/flagged/flagged.component';
import { PostResolver } from './posts/post.resolver';
import { PostsSearchComponent } from './posts/search/search.component';
import { PostsViewComponent } from './posts/view/view.component';
import { AppRootComponent } from './root.component';
import { UsersBrowseComponent } from './users/browse/browse.component';
import { UserEditComponent } from './users/edit/edit.component';
import { UserViewComponent } from './users/view/view.component';

export const routes: Routes = [
  { path: 'login', component: AppRootComponent },
  {
    path: '',
    component: AppRootComponent,
    canActivate: [BackofficeAuthGuard],
    children: [
      {
        path: 'users',
        component: UsersBrowseComponent,
      },
      {
        path: 'users/:userId/view',
        component: UserViewComponent,
      },
      {
        path: 'users/:userId/edit',
        component: UserEditComponent,
      },
      {
        path: 'posts',
        component: PostsSearchComponent,
      },
      {
        path: 'posts/featured',
        component: PostsFeaturedComponent,
      },
      {
        path: 'posts/flagged',
        component: PostsFlaggedComponent,
      },
      {
        path: 'posts/flagged/invalid',
        component: PostsFlaggedComponent,
        data: {
          type: FlaggedPostsTypes.invalid,
        },
      },
      {
        path: 'posts/flagged/valid',
        component: PostsFlaggedComponent,
        data: {
          type: FlaggedPostsTypes.valid,
        },
      },
      {
        path: 'posts/:postId/view',
        component: PostsViewComponent,
        resolve: {
          post: PostResolver,
        },
      },
      {
        path: 'channels',
        component: ChannelsBrowseComponent,
      },
      {
        path: 'channels/:channelId/view',
        component: ChannelViewComponent,
      },
      {
        path: 'payouts',
        component: PayoutsListComponent,
      },
      {
        path: 'payments/:sort',
        component: PaymentsListComponent,
      },
      {
        path: '',
        component: DashboardComponent,
        pathMatch: 'full',
      },
      { path: '**', redirectTo: '' }, // anything else redirect to Main Dashboard
    ],
  },
  { path: '**', redirectTo: '' }, // anything else redirect to Root
];
