<form>
  <div class="grid-x">
    <div class="input-group cell medium-12">
      <input [formControl]="searchParam" name="searchParam" class="input-group-field" type="text" placeholder="Search Parameter">
      <div class="input-group-button">
        <button id="search-button" (click)="search()" type="submit" class="button primary">Search</button>
        <button *ngIf="searchString !== ''" type="button" (click)="resetSearch()" class="button secondary">Clear</button>
      </div>
    </div>
  </div>
</form>
<div
  class="picker-container"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="200"
  [infiniteScrollDisabled]="endOfResults"
  (scrolled)="loadPosts()"
  *ngIf="postList && postList.length > 0"
>
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Channel</th>
        <th>User Display Name</th>
        <th>Created</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of postList">
        <td><a (click)="selectPost(p)">{{ p.name }}</a></td>
        <td>{{ p.channelName }}</td>
        <td>{{ p.userDisplayName }}</td>
        <td>{{ p.created | date: 'short'}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!loading && postList && postList.length === 0" data-alert class="alert-box info radius">
  No posts found
</div>
<div *ngIf="loading">
  <fa-icon [icon]="['fas', 'circle-notch']" [fixedWidth]="true" [spin]="true" size="2x"></fa-icon>
</div>