<header class="main-nav">
  <div class="grid-x">
    <div class="cell medium-3 shrink">
      <a routerLink="/" title="postd" class="logo" alt="postd">
        <img src="/assets/images/postd_tm_logo/SVG/postd.svg" />
      </a>
    </div>
    <div class="cell medium-9 shrink sub-menu">
      <a *ngIf="!isUserLoggedIn()" class="login-btn" (click)="onSigninClick()" title="Sign up / Login">
        Sign up / Login
      </a>
      <ul *ngIf="isUserLoggedIn()" class="user-menu">
        <li>
          <postd-dropdown-menu [dropdownClass]="'primary right profile-menu mobile'">
            <ul class="dropdown">
              <li><a (click)="logout()">Logout</a></li>
            </ul>
            <a class="dropdown-label profile-label">
              Signed in as {{ curUser.displayName }}
            </a>
          </postd-dropdown-menu>
        </li>
      </ul>
    </div>
  </div>
</header>
<!--postd-tooltip-container></postd-tooltip-container-->
<ng-template #signInModal [postdModalTemplate] let-dialog="dialogInstance">
  <div class="signup-modal">
    <div class="vex-close" (click)="dialog.close(true)"></div>
    <p class="spacer">Use a <a routerLink="https://transact.io" target="_blank"><img src="/assets/images/transact_logo_black.png" class="inline-transact-logo" alt="Transact" /></a> account on Postd.io</p>
    <p class="modal-buttons"><button class="button large primary" (click)="loginWithTransact()">Log in with Transact</button></p>
    <p class="modal-buttons"><button class="button secondary" (click)="loginWithTransact()">Register a new Transact account</button></p>
  </div>
</ng-template>
