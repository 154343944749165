// Pull the profile-image-component from Common to
// reuse in the backoffice app without dragging in all of the common elements

import { NgModule } from '@angular/core';
import { PostdCommonModule } from './common.module';

import { ProfileImageComponent } from '../frontend/core/forms/profile-image/profile-image.component';
import { SharedDirectivesModule } from './directives.module';

@NgModule({
  declarations: [ProfileImageComponent],
  imports: [PostdCommonModule, SharedDirectivesModule],
  exports: [ProfileImageComponent],
})
export class ProfileImageModule {}
