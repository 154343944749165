import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PostdSpinnerModule } from '@shared/components/postd-spinner/postd-spinner.module';

import { SharedDirectivesModule } from './../../app/common/directives.module';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { PaymentsListComponent } from './payments.component';
import { PostsModule } from '../posts/posts.module';

@NgModule({
  declarations: [PaymentsListComponent],
  exports: [PaymentsListComponent],
  imports: [
    CommonModule,
    PostdSpinnerModule,
    DropdownModule,
    SharedDirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    PostsModule,
  ],
})
export class PaymentsModule {}
