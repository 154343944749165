import { UntypedFormControl } from '@angular/forms';
import { Component, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Post } from '@shared/types/post';

import { PostService } from './../../services/post.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PostQueryParameters } from '@shared/services/post_service';

const POSTS_PER_PAGE = 20;
const postLists = new Map<string, Post[]>();

@Component({
  selector: 'postd-bo2-post-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss']
})
export class PickerComponent implements OnDestroy {
  @Output() selected = new EventEmitter();
  postList: Post[];
  searchParam: UntypedFormControl;
  searchString = '';
  page: number;
  endOfResults = false;

  loading = false;
  destroy = new Subject<void>();
  sortingOrder = 'dateDesc';

  constructor(
    public postService: PostService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.searchParam = new UntypedFormControl();
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  selectPost(post: Post) {
    this.selected.next(post);
  }

  loadPosts(resetList?: boolean) {
    if (this.endOfResults) {
      return;
    }

    const dataParams: PostQueryParameters = {
      query: this.searchString,
      fromOffset: this.page * POSTS_PER_PAGE,
      size: POSTS_PER_PAGE,
      sort: this.sortingOrder
    };

    this.loading = true;
    this.postService.queryPosts(dataParams).then((posts: Post[]) => {
      if (!posts || posts.length === 0) {
        this.endOfResults = true;
      } else {
        if (resetList) {
          this.postList = posts || [];
        } else {
          this.postList.push(...posts);
        }
      }
      this.loading = false;
    });

    this.page++;
  }

  search() {
    if (this.searchParam.value) {
      this.searchString = this.searchParam.value;
    } else {
      this.searchString = '';
    }

    this.reset();
  }

  resetSearch() {
    this.searchParam.setValue('');
    this.reset();
  }

  reset() {
    let postList = postLists.get(this.searchString);
    if (!postList) {
      postList = [];
      postLists.set(this.searchString, postList);
    }
    this.postList = postList;

    this.page = 0;
    this.endOfResults = false;
    this.loadPosts();
  }

}
