import {
  Component,
  AfterContentInit,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { CloseOnClickDirective } from './close-on-click.directive';

@Component({
  selector: 'postd-dropdown-menu',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements AfterContentInit {
  @Input() dropdownClass: string;
  @Input() position: string;
  @Input() disabled: boolean;
  @Input() simple: boolean;
  @Output() afterOpen = new EventEmitter();
  dropdownOpen: boolean;

  @ContentChildren(CloseOnClickDirective) closeHandlers: QueryList<CloseOnClickDirective>;
  @ViewChild('dropdownContent') dropdownContent: ElementRef;

  constructor() {
    this.disabled = false;
    this.dropdownOpen = false;
  }

  ngAfterContentInit() {
    const closeHandlersList = this.closeHandlers.toArray();
    closeHandlersList.forEach((closeHandler) => {
      closeHandler.clickState$.subscribe((clicked) => {
        if (clicked) {
          this.dropdownOpen = false;
        }
      });
    });

    if (this.position && this.position !== 'left' && this.position !== 'right') {
      console.error(`Invalid dropdown position "${this.position}"`);
    }
    if (this.simple !== false && this.simple !== undefined) {
      this.simple = true;
    }
  }

  toggleDropdown() {
    if (!this.disabled || this.dropdownOpen) {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        this.afterOpen.emit();
      }
    }
  }

  isDropdownOpened() {
    return this.dropdownOpen ? 'opened' : '';
  }
}
