import { SafeHtml } from '@angular/platform-browser';
import { User } from '@shared/types/user';

import { Observable, from, Subject } from 'rxjs';
import { Post, PostState } from '@shared/types/post';
import { Channel } from '@shared/types/channel';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { switchMap, map, mergeMap, tap, catchError } from 'rxjs/operators';

import { PostService } from './../../services/post.service';
import { UserService } from './../../services/user.service';
import { ChannelService } from './../../services/channel.service';
import { ActionQueueService } from './../../services/action-queue.service';
import { environment } from '@env/environment.test';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'postd-bo2-post-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class PostsViewComponent implements OnInit, OnDestroy {
  @Input() post: Observable<Post>;
  user = new Observable<User>();
  channel = new Observable<Channel>();
  leadContent = new Observable<SafeHtml>();
  premiumContent = new Observable<SafeHtml>();
  postdURLBase = environment.postdBaseUrl;
  pageSections = [];

  PostState = PostState;
  destroy = new Subject<void>();

  loadingFeatured = false;

  constructor(
    private userService: UserService,
    private postService: PostService,
    private channelService: ChannelService,
    private actionQueue: ActionQueueService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (!this.post) {
      this.post = this.activatedRoute.data.pipe(map(({ post }: { post: Post }) => post));
    }
    this.channel = this.post.pipe(
      switchMap((post) => this.channelService.getChannelInfo(post.channelId))
    );
    this.user = this.post.pipe(switchMap((post) => this.userService.getUser(post.userId)));
    this.leadContent = this.post.pipe(
      switchMap((post) => this.postService.loadPostLead(post)),
      map((post) => post.leadContent)
    );
    this.premiumContent = this.post.pipe(
      switchMap((post) => this.postService.loadPostPremium(post)),
      map((post) => post.premiumContent)
    );
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  featuredPostToggle(post: Post) {
    this.loadingFeatured = true;
    if (post.featured) {
      this.postService.removeFeaturedPost(post.id).subscribe(() => {
        this.loadingFeatured = false;
        post.featured = 0;
      });
    } else {
      from(this.postService.getFeaturedPosts())
        .pipe(
          mergeMap((featuredPosts) => {
            const newFeatured = featuredPosts.length + 1;
            return this.postService
              .setFeaturedPostOrder(post.id, newFeatured)
              .pipe(tap(() => (post.featured = newFeatured)));
          })
        )
        .subscribe(
          () => {
            this.loadingFeatured = false;
          },
          (err) => {
            post.featured = 0;
            console.error(err);
          }
        );
    }
  }

  setState(post: Post, state: PostState) {
    const originalState = post.state;
    if (state === PostState.TAKEN_DOWN) {
      post.state = state;
      this.actionQueue
        .addAction(
          `Suspended ${post.name}`,
          this.postService.setPostState(post.id, state),
          this.destroy
        )
        .pipe(catchError(() => (post.state = originalState)))
        .subscribe();
    } else {
      this.postService.setPostState(post.id, state).subscribe(
        () => {
          post.state = state;
        },
        (err) => {
          console.error(`could not set state for post ${post.id} to ${state}`, err);
        }
      );
    }
  }
}
