import { Component, OnInit } from '@angular/core';
import { ActionQueueService, Action } from './../../services/action-queue.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'postd-undo-actions',
  templateUrl: './undo-actions.component.html',
  styleUrls: ['./undo-actions.component.scss']
})
export class UndoActionsComponent {
  latestAction: Observable<Action>;

  constructor(actionQueue: ActionQueueService) {
    this.latestAction = actionQueue.latestAction;
  }

}
