import { BankService } from './../../services/bank.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { Payout, PayoutStatus, PayoutDestination } from '@shared/types/payout';
import { User } from '@shared/types/user';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'postd-bo2-payouts-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class PayoutsViewComponent implements OnInit {
  @Input() payout: Payout;
  user: Observable<User>;
  PayoutStatus = PayoutStatus;
  PayoutDestination = PayoutDestination;
  savingStatus = false;

  // Generate an array of options in the status enum
  statusOptions = Object.keys(PayoutStatus).reduce(
    (options, key) => {
      if (typeof PayoutStatus[key] === 'number') {
        options.push({ label: key, value: PayoutStatus[key] });
      }
      return options;
  }, []);

  staffNotes = new UntypedFormControl();
  constructor(private userService: UserService, private bankService: BankService) { }

  ngOnInit() {
    if (this.payout.staffNotes) {
      this.staffNotes.setValue(this.payout.staffNotes);
      this.staffNotes.markAsPristine();
    }
    this.user = this.userService.getUser(this.payout.userId);
  }

  setStatus(status: number) {
    this.savingStatus = true;
    this.bankService.updatePayout(this.payout.id, this.staffNotes.value, status)
      .subscribe(resp => {
        this.savingStatus = false;
        this.payout.status = status;
      }, err => {
          console.log('could not update payout', this.payout, err);
      });
  }

  saveNotes() {
    this.bankService.updatePayout(this.payout.id, this.staffNotes.value, this.payout.status)
      .subscribe(resp => {
        this.staffNotes.markAsPristine();
        this.payout.staffNotes = this.staffNotes.value;
      }, err => {
          console.log('could not update payout', this.payout, err);
      });
  }

}
