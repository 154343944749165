import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { from, Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Post } from '@shared/types/post';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { PostService } from './../../services/post.service';


@Component({
  selector: 'postd-bo2-post-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class PostsFeaturedComponent implements OnDestroy {
  postList: Observable<Post[]>;

  loading = false;

  @ViewChild('postDetailModal', { read: ModalDirective }) postDetailModal: ModalDirective;

  constructor(private postService: PostService) {
    this.loading = true;
    this.init();
  }

  private init() {
    this.postList = from(this.postService.getFeaturedPosts()).pipe(
      tap(() => this.loading = false)
    );
  }

  ngOnDestroy() {
    this.postDetailModal.close();
  }

  openPostDetail(post: Post) {
    this.postDetailModal.open({}, {
      postDetail: from(this.postService.fetchPost(post.id))
    }).subscribe(() => this.init()); // refresh post list after modal close
  }

  postsSorted(event: CdkDragDrop<HTMLElement>, postList: Post[]) {
    const oldPostList = [...postList];
    const element = postList.splice(event.previousIndex, 1)[0];
    postList.splice(event.currentIndex, 0, element);

    // Every post that got its index shifted will be between the start and end index of the moved post
    const postsToUpdate = postList
      .map((featuredPost, index) => {
        if (featuredPost.featured !== index + 1) {
          featuredPost.featured = index + 1;
          return featuredPost;
        }
        return undefined;
      })
      .filter(item => item !== undefined);
    if (postsToUpdate.length > 0) {
      forkJoin(postsToUpdate.map(
        post => this.postService.setFeaturedPostOrder(post.id, post.featured)
      )).subscribe(() => {}, (err) => {
        console.error(err);
        this.postList = of(oldPostList);
      });
    }
  }

}
