
import { Address } from './address';
import { Bank as IBank } from './bank';


export enum PayoutStatus {
  // constant members
  Invalid = 0,
  Requested = 1, //
  Processing = 2, //
  Sent = 3, //
  Rejected = 4, //
}

export enum PayoutDestination {
  // constant members
  Invalid = 0,
  Bank = 1, //
  Check = 2, //
  Transact = 3, // Payment to transact
  Last = 4, //
}


export class Payout {

  public id: string;
  public userId: number; // transact user id
  public teamId: string; // If for a team, their ID

  public destType: PayoutDestination;  // where to send funds


  public address: Address; // if check
  public bank: IBank; // if sent to bank
  public xsactReceiver: number; // if sent to transact

  public status: PayoutStatus;

  public currency: string;
  public amountReq: number;  // dictionary currency / funds requested
  public fee: number;  // dictionary currency / funds.  service fee
  public amountSent: number;  // dictionary currency / funds requested
  public staffNotes: string;  // staff can write notes here

  public reqIpAddress: string;

  public created: number;
  public updated: number;
}
