<ng-container *ngIf="user">
  <h3>
    <div class="grid-x">
      <div class="cell auto">
        <a (click)="back()"><fa-icon [icon]="['fas', 'arrow-left']" title="Go Back"></fa-icon></a>
        User {{ user.displayName }}
      </div>
      <div class="cell shrink">
        <a class="button small alert" (click)="confirmDeleteUser()">Delete User</a>
      </div>
    </div>
  </h3>
  <ul class="accordion">
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.general }">
      <a (click)="pageSections.general = !pageSections.general" class="accordion-title"
        >General Information</a
      >
      <div class="accordion-content general">
        <section class="general__user-profile">
          <div class="general__user-profile__column avatar--col">
            <div class="general__user-profile__column__holder">
              <span
                [ngClass]="{ 'no--image': !user.profileImageUrl }"
                class="general__user-profile__column__avatar"
              >
                <img class="user-pic" *ngIf="user.profileImageUrl" [src]="user.profileImageUrl" />
                <img class="default-pic" src="/assets/icons/user.svg" />
              </span>
              <span class="general__user-profile__column__info">
                <p *ngIf="user.firstName && user.lastName">
                  {{ user.firstName }} {{ user.lastName }}
                </p>
                <p *ngIf="user.email1">
                  {{ user.email1 }}
                </p>
              </span>
            </div>
            <div class="general__user-profile__column__funds">
              <span class="general__user-profile__column__funds__label">User Funds</span>
              <span class="general__user-profile__column__funds__numbers">{{
                user.funds?.PROD / 10000 | currency : 'USD'
              }}</span>
            </div>
          </div>
          <div class="general__user-profile__column">
            <div class="general__user-profile__column__holder">
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">ID</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.id
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">e-mail</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.email1
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >First Name</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.firstName
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">Last Name</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.lastName
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Display Name</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.displayName
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Account Closed</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.accountClosed ? 'Yes' : 'No'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">Admin</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.admin ? 'Yes' : 'No'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">Banned</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.banned ? 'Yes' : 'No'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Reputation</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.reputation ? 'Yes' : 'No'
                }}</span>
              </div>
            </div>
          </div>
          <div class="general__user-profile__column">
            <div class="general__user-profile__column__holder">
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">Seller</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.seller ? 'Yes' : 'No'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Last Logout</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.logoutDate | date : 'short'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">Created</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.created | date : 'short'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label">Updated</span>
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.updated | date : 'short'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Transact ID</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.xsactUserId
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Notify Follow</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.notifyFollow ? 'Yes' : 'No'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Notify My Comments</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.notifyMyComms ? 'Yes' : 'No'
                }}</span>
              </div>
              <div class="general__user-profile__column__holder__wrapper">
                <span class="general__user-profile__column__holder__wrapper__label"
                  >Notify My Posts</span
                >
                <span class="general__user-profile__column__holder__wrapper__info">{{
                  user.notifyMyPosts ? 'Yes' : 'No'
                }}</span>
              </div>
            </div>
          </div>
        </section>

        <table class="compact">
          <thead>
            <th></th>
            <th>ID</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Display Name</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <img
                  *ngIf="user.profileImageUrl"
                  [src]="user.profileImageUrl"
                  style="max-width: 150px"
                />
              </td>
              <td>{{ user.id }}</td>
              <td>{{ user.email1 }}</td>
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>{{ user.displayName }}</td>
            </tr>
          </tbody>
        </table>
        <table class="compact">
          <thead>
            <th>Account Closed</th>
            <th>Admin</th>
            <th>Banned</th>
            <th>Reputation</th>
            <th>Seller</th>
            <th>Last Logout</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ user.accountClosed ? 'Yes' : 'No' }}</td>
              <td>{{ user.admin ? 'Yes' : 'No' }}</td>
              <td>{{ user.banned ? 'Yes' : 'No' }}</td>
              <td>{{ user.reputation ? 'Yes' : 'No' }}</td>
              <td>{{ user.seller ? 'Yes' : 'No' }}</td>
              <td>{{ user.logoutDate | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
        <table class="compact">
          <thead>
            <th>Created</th>
            <th>Updated</th>
            <th>Transact ID</th>
            <th>Notify Follow</th>
            <th>Notify My Comments</th>
            <th>Notify My Posts</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ user.created | date : 'short' }}</td>
              <td>{{ user.updated | date : 'short' }}</td>
              <td>{{ user.xsactUserId }}</td>
              <td>{{ user.notifyFollow ? 'Yes' : 'No' }}</td>
              <td>{{ user.notifyMyComms ? 'Yes' : 'No' }}</td>
              <td>{{ user.notifyMyPosts ? 'Yes' : 'No' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.channels }">
      <a
        name="channels"
        (click)="pageSections.channels = !pageSections.channels"
        class="accordion-title"
      >
        User Channels
      </a>

      <div class="accordion-content">
        <table class="compact">
          <thead>
            <th>Channel Name</th>
            <th>Post Count</th>
            <th>Purchase Count</th>
            <th>View Count</th>
            <th>Follower Count</th>
            <th>Created</th>
          </thead>
          <tbody>
            <tr *ngFor="let ch of userChannels">
              <td class="align-left">
                <a [routerLink]="['/channels', ch.id, 'view']" target="_blank">{{ ch.name }}</a>
              </td>
              <td>{{ ch.numPosts }}</td>
              <td>{{ ch.numPurchases }}</td>
              <td>{{ ch.numViews }}</td>
              <td>{{ ch.followers }}</td>
              <td>{{ ch.created | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.sales }">
      <a name="channels" (click)="pageSections.sales = !pageSections.sales" class="accordion-title">
        User Sales
      </a>

      <div class="accordion-content">
        <table class="compact">
          <thead>
            <th>Channel Name</th>
            <th>Post Name</th>
            <th>Price</th>
            <th>Earnings</th>
            <th>Postd Commission</th>
            <th>IP Address</th>
            <th>Buyer</th>
            <th>State</th>
            <th>Date</th>
          </thead>
          <tbody>
            <tr *ngFor="let sale of userSales">
              <td class="align-left">
                <a [routerLink]="['/channels', sale.channelId, 'view']" target="_blank">
                  {{ sale.channelName }}
                </a>
              </td>
              <td class="align-left">
                <a [attr.href]="postdURLBase + '/post/' + sale.postId + '/'" target="_blank">
                  {{ sale.postName }}
                </a>
              </td>
              <td>{{ sale.price / 100 | currency : 'USD' : 'symbol' : '1.2-2' }}</td>
              <td>{{ sale.earnings }}</td>
              <td>{{ sale.commission }}</td>
              <td>{{ sale.ipAddress }}</td>
              <td>
                <a [routerLink]="['/users', sale.buyerId, 'view']" target="_blank">
                  {{ sale.buyerLabel }}
                </a>
              </td>
              <td>{{ sale.state }}</td>
              <td>{{ sale.date | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
        <button class="button primary" *ngIf="!loadingSales" (click)="loadUserSales()">
          Load more sales
        </button>
        <button class="button primary" *ngIf="loadingSales" disabled>
          <fa-icon [icon]="['fas', 'circle-notch']" [fixedWidth]="true" [spin]="true"></fa-icon>
        </button>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.banking }">
      <a
        name="channels"
        (click)="pageSections.banking = !pageSections.banking"
        class="accordion-title"
      >
        Banking Information
      </a>

      <div class="accordion-content">
        <table class="compact">
          <thead>
            <th>Name</th>
            <th>Account Holder</th>
            <th>IBAN</th>
            <th>Swift</th>
            <th>Account Information</th>
            <th>Updated</th>
          </thead>
          <tbody>
            <tr *ngFor="let bank of userBankingInfo">
              <td>
                {{ bank.name }}
              </td>
              <td>
                {{ bank.accountHolder }}
              </td>
              <td>
                {{ bank.iban }}
              </td>
              <td>
                {{ bank.swift }}
              </td>
              <td>
                <p *ngIf="bank.routingNumber">
                  <label>Routing Number</label>
                  <span class="account-info">{{ bank.routingNumber }}</span>
                </p>
                <p *ngIf="bank.accountNumber">
                  <label>Account Number</label>
                  <span class="account-info">{{ bank.accountNumber }}</span>
                </p>
              </td>
              <td>{{ bank.updated | date : 'short' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.taxes }">
      <a (click)="pageSections.taxes = !pageSections.taxes" class="accordion-title"
        >Tax Documents</a
      >

      <div class="accordion-content">
        <table class="compact" *ngIf="taxDocumentUrl; else noTaxDocument">
          <tbody>
            <tr>
              <td style="width: 50%">
                <div class="thumbnail">
                  <img [src]="taxDocumentUrl" (error)="taxDocumentUrl = undefined" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #noTaxDocument>
          <span>No tax document found for user</span>
        </ng-template>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.payouts }">
      <a
        name="payouts"
        (click)="pageSections.payouts = !pageSections.payouts"
        class="accordion-title"
        >Payouts</a
      >

      <div class="accordion-content">
        <table class="compact" *ngIf="payouts && payouts.length > 0; else noPayouts">
          <thead>
            <tr>
              <th>Date of Payout</th>
              <th>Bank</th>
              <th>Amount<br />Requested</th>
              <th>Funds</th>
              <th>Amount<br />Sent</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody *ngIf="user">
            <tr *ngFor="let p of payouts">
              <td class="align-top">{{ p.created | date : 'short' }}</td>
              <td class="align-top">
                <span style="white-space: pre-line" *ngIf="p.bank.routingNumber">{{
                  'Routing: ' + p.bank?.routingNumber + '\n'
                }}</span>
                <span style="white-space: pre-line" *ngIf="p.bank.accountNumber">{{
                  'Account: ' + p.bank?.accountNumber + '\n'
                }}</span>
                <span style="white-space: pre-line" *ngIf="p.bank.iban">{{
                  'IBAN: ' + p.bank.iban + '\n'
                }}</span>
                <span style="white-space: pre-line" *ngIf="p.bank.swift">{{
                  'Swift: ' + p.bank.swift
                }}</span>
              </td>
              <td class="align-top text-center">{{ p.amountReq / 100 | currency : 'USD' }}</td>
              <!-- Need to check the units! -->
              <td class="align-top text-center">
                {{ user?.funds?.PROD / 10000 | currency : 'USD' }}
              </td>
              <td class="align-top text-center">{{ p.amountSent / 100 | currency : 'USD' }}</td>
              <td class="align-top text-center">{{ PayoutStatus[p.status] }}</td>
              <td class="align-top text-center">
                <a (click)="showPayoutDetails(p)">
                  <fa-icon [icon]="['fas', 'info']"></fa-icon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #noPayouts>
          <span>No payout items for user</span>
        </ng-template>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.comments }">
      <a
        name="comments"
        (click)="pageSections.comments = !pageSections.comments"
        class="accordion-title"
        >Comments</a
      >

      <div class="accordion-content">
        <table *ngIf="comments && comments.length > 0; else noComments">
          <thead>
            <tr>
              <th colspan="2" class="align-left">
                <div class="pretty p-default p-curve p-thick">
                  <input
                    type="checkbox"
                    [checked]="selectAllComments"
                    (change)="selectAllComments = !selectAllComments"
                  />
                  <div class="state p-info"><label></label></div>
                </div>
              </th>
              <th>Abuse</th>
              <th>Likes</th>
              <th>Dislikes</th>
              <th>Created</th>
              <th>Edited</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of comments; let i = index">
              <td class="align-left">
                <div class="pretty p-default p-curve p-thick">
                  <input
                    type="checkbox"
                    [checked]="r.selected"
                    (change)="r.selected = !r.selected"
                  />
                  <div class="state p-info"><label></label></div>
                </div>
              </td>
              <td class="align-left">{{ r.comments }}</td>
              <td>{{ r.abuse }}</td>
              <td>{{ r.likes }}</td>
              <td>{{ r.dislikes }}</td>
              <td class="nowrap">{{ r.created | date : 'short' }}</td>
              <td [attr.title]="r.updated | date : 'short'">
                {{ r.updated !== r.created ? 'Yes' : 'No' }}
              </td>
              <td>
                <a [attr.href]="postdURLBase + '/post/' + r.postId + '/'" target="_blank">
                  <fa-icon [icon]="['fas', 'external-link']" title="Show Post"></fa-icon>
                </a>
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #noComments>
          <span>No user comments or reviews.</span>
        </ng-template>

        <div class="grid-x">
          <div class="cell shrink">
            <button
              class="button secondary"
              (click)="deleteComments(selectedComments)"
              [disabled]="selectedComments.length === 0"
            >
              Delete Selected Comments
            </button>
          </div>
        </div>
      </div>
    </li>
    <li class="accordion-item" [ngClass]="{ 'is-active': pageSections.reviews }">
      <a (click)="pageSections.reviews = !pageSections.reviews" class="accordion-title"
        >Reviews and Ratings</a
      >

      <div class="accordion-content">
        <table class="compact">
          <thead>
            <tr>
              <th colspan="2" class="align-left">
                <div class="pretty p-default p-curve p-thick">
                  <input
                    type="checkbox"
                    [checked]="selectAllReviews"
                    (change)="selectAllReviews = !selectAllReviews"
                  />
                  <div class="state p-info"><label></label></div>
                </div>
              </th>
              <th class="align-left">Comment</th>
              <th>Rating</th>
              <th>Created</th>
              <th>Edited</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of reviews; let i = index">
              <td class="align-left">
                <div class="pretty p-default p-curve p-thick">
                  <input
                    type="checkbox"
                    [checked]="r.selected"
                    (change)="r.selected = !r.selected"
                  />
                  <div class="state p-info"><label></label></div>
                </div>
              </td>
              <td class="align-left">{{ r.subject }}</td>
              <td class="align-left">{{ r.comments }}</td>
              <td>{{ r.rating }}</td>
              <td>{{ r.created | date : 'short' }}</td>
              <td [attr.title]="r.updated | date : 'short'">
                {{ r.updated !== r.created ? 'Yes' : 'No' }}
              </td>
              <td>
                <a [attr.href]="postdURLBase + '/post/' + r.postId + '/'" target="_blank">
                  <fa-icon [icon]="['fas', 'external-link']" title="Show Post"></fa-icon>
                </a>
              </td>
            </tr>
            <tr *ngIf="!reviews || reviews.length <= 0">
              <td colspan="7">{{ 'No user reviews.' }}</td>
            </tr>
          </tbody>
        </table>

        <div class="grid-x">
          <div class="cell shrink">
            <button
              class="button secondary"
              (click)="deleteReviews(selectedReviews)"
              [disabled]="selectedReviews.length === 0"
            >
              Delete Selected Reviews
            </button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</ng-container>
<ng-template #deleteUserModal [postdModalTemplate] let-dialogRef="dialogInstance">
  <div class="delete-modal">
    <div class="vex-close" (click)="user && dialogRef.close(true)"></div>
    <p>
      Are you sure you want to delete user <strong>{{ user.displayName }}</strong
      >?
    </p>
    <div class="modal-actions right">
      <button class="button alert" (click)="deleteUser()">Yes</button>
      <button class="button secondary" (click)="dialogRef.close(true)">Cancel</button>
    </div>
  </div>
</ng-template>
<ng-template
  #payoutDetailsModal
  [postdModalTemplate]="{ contentClassName: 'large-modal' }"
  let-dialog="dialogInstance"
>
  <div>
    <div class="vex-close" (click)="dialog.close(true) && (displayPayoutDetail = null)"></div>
    <postd-bo2-payouts-view
      *ngIf="displayPayoutDetail"
      [payout]="displayPayoutDetail"
    ></postd-bo2-payouts-view>
  </div>
</ng-template>
