import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Observable, forkJoin, of, Subject, BehaviorSubject, empty, combineLatest } from 'rxjs';
import { switchMap, map, shareReplay, catchError, withLatestFrom } from 'rxjs/operators';
import { Playlist } from '@shared/types/playlist';
import { User } from '@shared/types/user';
import { Channel } from '@shared/types/channel';
import { Post, PostState } from '@shared/types/post';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { environment } from '@env/environment.test';

import { UserService } from './../../services/user.service';
import { PostService } from './../../services/post.service';
import { ChannelService } from './../../services/channel.service';
import { PlaylistService } from './../../services/playlist.service';
import { ActionQueueService } from './../../services/action-queue.service';

interface PostPickerModalData {
  playlist: Playlist;
}

interface PostModalData {
  postDetail: Observable<Post>;
  picking?: boolean;
}

@Component({
  selector: 'postd-bo2-playlist-view',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class ChannelPlaylistComponent implements OnInit, OnDestroy {
  @Input() playlist: Observable<Playlist>;
  channel: Observable<Channel>;
  user: Observable<User>;
  postList: Observable<Post[]>;
  channelPostList: Observable<Post[]>;
  pageSections = [true];
  postSelectTab = 0;
  postdURLBase = environment.apiServer;

  removedPostIds = new BehaviorSubject<string[]>([]);
  selectedPostIds = new Set<string>();

  postAddError: any;

  destroy = new Subject<void>();

  @ViewChild('postDetailModal', { read: ModalDirective }) postDetailModal: ModalDirective;
  @ViewChild('postPickerModal', { read: ModalDirective }) postPickerModal: ModalDirective;

  constructor(
    private userService: UserService,
    private postService: PostService,
    private channelService: ChannelService,
    private playlistService: PlaylistService,
    private actionQueue: ActionQueueService
  ) { }

  ngOnInit() {
    this.channel = this.playlist.pipe(
      switchMap(playlist => this.channelService.getChannelInfo(playlist.channelId)),
      shareReplay(1)
    );
    this.user = this.playlist.pipe(
      switchMap(playlist => this.userService.getUser(playlist.userId)),
      shareReplay(1)
    );
    this.postList = combineLatest(this.playlist, this.removedPostIds).pipe(
      switchMap(([playlist, removedPostIds]) => {
        const idsToFetch = playlist.postIds.filter(postId => !removedPostIds.includes(postId));
        return forkJoin(
          idsToFetch.map(
            postId => this.postService.getPostInfo(postId)
              .pipe(
                catchError(_ => of({ id: postId, name: '(Post not found)'} as Post))
              )
          )
        ).pipe(
          map(posts => posts.filter(post => post !== undefined))
        );
      }),
      shareReplay(1)
    );
    this.channelPostList = this.playlist.pipe(
      switchMap(playlist => this.postService.getChannelPosts(playlist.channelId, 0, 20, '', '', 'dateAsc')),
      map(resp => resp.posts.filter((post: Post) => post.state !== PostState.HIDDEN)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.postDetailModal.close();
    this.postPickerModal.close();
    this.destroy.next();
  }

  postsSorted(event: CdkDragDrop<HTMLElement>, playlist: Playlist, postList: Post[]) {
    const oldPostList = [...postList];
    const element = postList.splice(event.previousIndex, 1)[0];
    postList.splice(event.currentIndex, 0, element);

    this.playlistService.editPlaylistInfo(
      playlist,
      { postIds: postList.map(p => p.id ) }
    ).subscribe(() => {}, (err) => {
      console.error(err);
      this.postList = of(oldPostList);
    });
  }

  openPostDetail(post: Post) {
    const data: PostModalData = {
      postDetail: this.postService.fetchPost(post.id)
    };
    this.postDetailModal.open({}, data);
  }

  openPostPicker(playlist: Playlist) {
    const data: PostPickerModalData = { playlist };
    this.postPickerModal.open({ overlayClosesOnClick: false }, data);
  }

  selectPostToAddToPlaylist(post: Post) {
    const data: PostModalData = {
      postDetail: this.postService.fetchPost(post.id),
      picking: true
    };
    this.postAddError = undefined;
    this.postDetailModal.open({}, data);
  }

  addPostToPlaylist(post: Post, playlist: Playlist) {
    this.postAddError = undefined;
    this.playlistService.addPostToPlaylist(playlist.id, post.id)
      .subscribe(() => {
        playlist.postIds.push(post.id);
        this.postList = forkJoin(playlist.postIds.map(postId => this.postService.getPostInfo(postId)));
        this.postDetailModal.close();
        this.postPickerModal.close();
      }, resp => {
        this.postAddError = resp.error;
      });
  }

  togglePostSelected(post: Post) {
    if (this.selectedPostIds.has(post.id)) {
      this.selectedPostIds.delete(post.id);
    } else {
      this.selectedPostIds.add(post.id);
    }
  }

  removeSelectedPosts(playlist: Playlist) {
    const originalState = this.removedPostIds.value;
    const selectedPostIds = Array.from(this.selectedPostIds.values());
    const removePostsObservable = this.playlistService.removePostsFromPlaylist(playlist, selectedPostIds);

    this.removedPostIds.next([ ...originalState, ...selectedPostIds ]);
    this.actionQueue.addAction(
      `Deleted ${this.selectedPostIds.size} post${this.selectedPostIds.size > 1 ? 's' : ''}`,
      removePostsObservable,
      this.destroy
    ).pipe(
      catchError(() => {
        this.removedPostIds.next(originalState);
        return empty();
      })
    ).subscribe();
  }
}
