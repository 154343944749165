<div class="row">
  <div class="medium-12 item">
    <label class="item__label">Payout ID</label>
    <span>{{ payout.id }}</span>
  </div>
  <div class="medium-12 item" *ngIf="user | async as u">
    <label class="item__label">User</label>
    <a [routerLink]="['/users', payout.userId, 'view']">{{ u.displayName }}</a>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Team ID</label>
    <span>{{ payout.teamId }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Status</label>
    <postd-dropdown-menu class="menu select" > <!-- [dropdownClass]="'text-right'" -->
        <ul class="dropdown checkbox-list" postdCloseOnClick>
            <li *ngFor="let s of statusOptions">
                <div class="pretty p-default" >
                    <input type="checkbox" [checked]="s.value === payout.status" (click)="setStatus(s.value)" />
                    <div class="state p-info"><label>{{s.label}}</label></div>
                </div>
            </li>
        </ul>
        <a class="dropdown-label">
          {{ PayoutStatus[payout.status] }}
          <fa-icon [icon]="['fas', 'circle-notch']" [spin]="true" *ngIf="savingStatus"></fa-icon>
          <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
        </a>
    </postd-dropdown-menu>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Destination</label>
    <span>{{ PayoutDestination[payout.destType] }}</span>
  </div>
  <ng-container [ngSwitch]="payout.destType">
    <div class="medium-12 item" *ngSwitchCase="1">
      <label class="item__label">Bank Information</label>
      <div *ngIf="payout.bank">
        <div class="item">
          <label class="item__label">Bank Name</label>
          <span>{{ payout.bank.name }}</span>
        </div>
        <div class="item">
          <label class="item__label">Account Holder</label>
          <span>{{ payout.bank.accountHolder }}</span>
        </div>
        <div class="item">
          <label class="item__label">Routing Number</label>
          <span>{{ payout.bank.routingNumber }}</span>
        </div>
        <div class="item">
          <label class="item__label">Account Number</label>
          <span>{{ payout.bank.accountNumber }}</span>
        </div>
        <div class="item">
          <label class="item__label">IBAN</label>
          <span>{{ payout.bank.iban }}</span>
        </div>
        <div class="item">
          <label class="item__label">Swift</label>
          <span>{{ payout.bank.swift }}</span>
        </div>
      </div>
    </div>
    <div class="medium-12 item" *ngSwitchCase="2">
      <label class="item__label">Address</label>
      <div *ngIf="payout.address">
        <div class="item">
          <label class="item__label">Line 1</label>
          <span>{{ payout.address.line1 }}</span>
        </div>
        <div class="item">
          <label class="item__label">Line 2</label>
          <span>{{ payout.address.line2 }}</span>
        </div>
        <div class="item">
          <label class="item__label">City</label>
          <span>{{ payout.address.city }}, {{ payout.address.region }}</span>
        </div>
        <div class="item">
          <label class="item__label">Postal Code</label>
          <span>{{ payout.address.postalCode }}</span>
        </div>
        <div class="item">
          <label class="item__label">Country</label>
          <span>{{ payout.address.country }}</span>
        </div>
      </div>
    </div>
    <div class="medium-12 item" *ngSwitchCase="3">
      <label class="item__label">Send to Transact</label>
      <span>{{ payout.xsactReceiver }}</span>
    </div>
  </ng-container>
  <div class="medium-12 item">
    <label class="item__label">Currency</label>
    <span>{{ payout.currency }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Amount Requested</label>
    <span>{{ payout.amountReq / 100  | currency:'USD' }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Fee</label>
    <span>{{ payout.fee / 100  | currency:'USD' }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Amount Sent</label>
    <span>{{ payout.amountSent / 100  | currency:'USD' }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Created</label>
    <span>{{ payout.created | date:'medium' }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Updated</label>
    <span>{{ payout.updated | date:'medium' }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Requesting IP</label>
    <span>{{ payout.reqIpAddress }}</span>
  </div>
  <div class="medium-12 item">
    <label class="item__label">Staff Notes</label>
    <div>
      <textarea class="medium-12 columns" [formControl]="staffNotes"></textarea>
      <p>
        <button class="button" (click)="saveNotes()" [disabled]="staffNotes.pristine">
          Save
        </button>
      </p>
    </div>
  </div>

</div>