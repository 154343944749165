<ng-container *ngIf="playlist | async as p">
  <h4>
    {{ p.name }}
    <a class="external-link" [attr.href]="postdURLBase + '/playlist/' + p.id + '/'" target="_blank">
      <fa-icon [icon]="['fas', 'external-link']" title="Show Playlist" ></fa-icon>
    </a>
  </h4>
  <table class="compact">
    <thead>
      <th></th>
      <th>Channel</th>
      <th>User</th>
      <th>Created</th>
      <th>Updated</th>
    </thead>
    <tbody>
      <tr>
        <td>
          <img
            *ngIf="p.thumbnailImage"
            [src]="p.thumbnailImage"
            style="max-width:150px"/>
        </td>
        <td><a [routerLink]="['/channels', p.channelId, 'view']">{{ (channel | async)?.name }}</a></td>
        <td><a [routerLink]="['/users', p.userId, 'view']">{{ (user | async)?.displayName }}</a></td>
        <td>{{ p.created | date: 'short'}}</td>
        <td>{{ p.updated | date: 'short'}}</td>
      </tr>
    </tbody>
  </table>
  <table class="compact">
    <thead>
      <th>Description</th>
      <th>Purchases</th>
      <th>Views</th>
    </thead>
    <tbody>
      <tr>
        <td>{{ p.description }}</td>
        <td>{{ p.purchaseCount }}</td>
        <td>{{ p.viewCount }}</td>
      </tr>
    </tbody>
  </table>
  <ul class="accordion">
    <li class="accordion-item" [ngClass]="{'is-active': pageSections[0]}">
      <a (click)="pageSections[0] = !pageSections[0]" class="accordion-title">Posts</a>
  
      <div class="accordion-content">
        <table *ngIf="postList | async as posts">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Name</th>
                <th>State</th>
                <th>Purchased</th>
                <th>Created</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="postsSorted($event, p, posts)">
              <tr *ngFor="let post of posts" cdkDrag>
                <td class="drag-handle"><fa-icon class="icon" [icon]="['fas', 'ellipsis-v']"></fa-icon></td>
                <td class="align-left">
                  <div class="pretty p-default p-curve p-thick" >
                    <input type="checkbox" 
                      [checked]="this.selectedPostIds.has(post.id)"
                      (change)="togglePostSelected(post)" />
                    <div class="state p-info"><label></label></div>
                  </div>
                </td>
                <td><a (click)="openPostDetail(post)">{{ post.name }}</a></td>
                <td>{{ post.state }}</td>
                <td>{{ post.purchaseCount }}</td>
                <td>{{ post.created | date: 'short'}}</td>
                <td>{{ post.updated | date: 'short'}}</td>
              </tr>
            </tbody>
          </table>
          <div class="grid-x modal-actions">
            <div class="cell shrink">
              <button class="button" (click)="openPostPicker(p)">
                Add Post
              </button>
            </div>
            <div class="cell shrink">
              <button class="button secondary"
                (click)="removeSelectedPosts(p)"
                [disabled]="selectedPostIds.size === 0"
              >
                Delete Selected Reviews
              </button>
            </div>
          </div>
      </div>
    </li>
  </ul>
</ng-container>
<ng-template
  #postDetailModal
  [postdModalTemplate]="{ 'contentClassName': 'large-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <postd-bo2-post-view *ngIf="data.postDetail" [post]="data.postDetail">
    <div class="post-actions callout alert" *ngIf="postAddError">
      <h5>Error adding post to playlist</h5>
      <p>{{ postAddError | json }}</p>
    </div>
    <div class="post-actions callout primary" *ngIf="data.picking">
      <div class="grid-x modal-actions right" *ngIf="playlist | async as playlist">
        <div class="cell">
          <h5>Adding post to playlist {{ playlist.name }}</h5>
        </div>
        <div class="cell auto"></div>
        <div class="cell shrink">
          <button class="button secondary" (click)="dialog.close(true)">
            Cancel
          </button>
        </div>
        <div class="cell shrink" *ngIf="data.postDetail | async as post">
          <button class="button primary"
            (click)="addPostToPlaylist(post, playlist);"
          >
            Add Post
          </button>
        </div>
      </div>
    </div>
  </postd-bo2-post-view>
</ng-template>
<ng-template
  #postPickerModal
  [postdModalTemplate]="{ 'contentClassName': 'medium-modal' }"
  let-dialog="dialogInstance"
  let-data="data"
>
  <div class="vex-close" (click)="dialog.close(true)"></div>
  <h4>Select Post</h4>

  <div class="grid-x grid-margin-x">
    <div class="cell medium-12">
      <ul class="tabs">
        <li class="tabs-title" [ngClass]="{'is-active': postSelectTab === 0}">
          <a (click)="postSelectTab = 0">Channel Posts</a>
        </li>
        <li class="tabs-title" [ngClass]="{'is-active': postSelectTab === 1}">
          <a (click)="postSelectTab = 1">Search All Posts</a>
        </li>
      </ul>
      <div class="tabs-content">
        <div class="tabs-panel" [ngClass]="{'is-active': postSelectTab === 0}">
          <table *ngIf="channelPostList | async as posts">
            <thead>
              <tr>
                <th>Name</th>
                <th>State</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let post of posts">
                <td><a (click)="selectPostToAddToPlaylist(post, data)">{{ post.name }}</a></td>
                <td>{{ post.state }}</td>
                <td>{{ post.created | date: 'short'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tabs-panel" [ngClass]="{'is-active': postSelectTab === 1}">
          <postd-bo2-post-picker (selected)="selectPostToAddToPlaylist($event, data)"></postd-bo2-post-picker>
        </div>
      </div>
    </div>
    <!-- <div class="cell medium-5 preview-box">
      <ng-container *ngFor="let selectedPost of [data.selectedPost | async];">
        <div *ngIf="selectedPost">
          <img [attr.src]="selectedPost.thumbnailImage" style="width: 100%;" />
          <h5>{{selectedPost.name}}</h5>
        </div>
        <div *ngIf="!selectedPost"></div>

        <div class="grid-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <button class="button secondary" (click)="dialog.close(true)">
              Cancel
            </button>
          </div>
          <div class="cell shrink">
            <button class="button primary"
              (click)="addPostToPlaylist(selectedPost, data.playlist)"
              [disabled]="!selectedPost"
            >
              Select Post
            </button>
          </div>
        </div>
      </ng-container>
    </div> -->
  </div>
</ng-template>
