import { forEach } from 'lodash';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

import { CacheService } from '@shared/services/cache_service';
import { PostService as ClientPostService } from '@shared/services/post_service';
import { PurchaseService } from '@shared/services/purchase_service';
import { TrackingService } from '@shared/services/tracking_service';
import { LocationService } from '@shared/services/location_service';
import { Post } from '@shared/types/post';
import { FlagReason, FlagAction } from '@shared/types/flagging';

import { ChannelService } from './channel.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

export interface IFlaggedPost {
  actionTaken: FlagAction;
  channelId: string;
  comments: string;
  created: number;
  id: string;
  postId: string;
  reason: FlagReason;
  reasonStrings?: string[];
  state: string;
  updated: number;
  userId: number;
}

@Injectable({
  providedIn: 'root'
})
export class PostService extends ClientPostService {
  constructor(
    _sanitizer: DomSanitizer,
    locationService: LocationService,
    channelService: ChannelService,
    cacheService: CacheService,
    trackingService: TrackingService,
    purchaseService: PurchaseService,
    userService: UserService,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) document,
    @Inject(PLATFORM_ID) platformId
  ) {
    super(
      _sanitizer,
      locationService,
      channelService,
      cacheService,
      trackingService,
      purchaseService,
      userService,
      httpClient,
      document,
      platformId
    );
  }

  deleteComment(postId: string, commentId: string) {
      const url = '/api/post/' + postId + '/comment/' + commentId + '/remove';
      return this.httpClient.delete(url, {});
  }

  getFlaggedComments() {
      const url = '/api/admin/flagged/post-comments/NEW';
      return this.httpClient.get<any[]>(url, {});
  }

  acceptCommentFlag(flagId: string) {
      const url = '/api/admin/flagged/post-comment/accept/takedown/' + flagId;
      return this.httpClient.post<any>(url, {});
  }

  rejectCommentFlag(flagId: string) {
      const url = '/api/admin/flagged/post-comment/reject/takedown/' + flagId;
      return this.httpClient.post<any>(url, {});
  }

  getFlaggedPosts(): Observable<IFlaggedPost[]> {
      return this.httpClient.get<IFlaggedPost[]>('/api/admin/flagged/posts/NEW', {}).pipe(
        this.flagReasonsMap()
      );
  }

  getFlaggedInvalidPosts(): Observable<IFlaggedPost[]> {
      return this.httpClient.get<IFlaggedPost[]>('/api/admin/flagged/posts/INVALID', {}).pipe(
        this.flagReasonsMap()
      );
  }

  getFlaggedValidPosts(): Observable<IFlaggedPost[]> {
      return this.httpClient.get<IFlaggedPost[]>('/api/admin/flagged/posts/VALID', {}).pipe(
        this.flagReasonsMap()
      );
  }

  acceptPostFlag(flagId: string) {
    const url: string = '/api/admin/flagged/post/accept/takedown/' + flagId;
    return this.httpClient.post(url, {});
  }

  rejectPostFlag(flagId: string) {
    const url: string = '/api/admin/flagged/post/reject/takedown/' + flagId;
    return this.httpClient.post(url, {});
  }

  setFeaturedPostOrder(postId: string, order: number): Observable<any> {
    const url: string = '/api/admin/post/' + postId + '/featured/' + order;
    return this.httpClient.post(url, {});
  }

  removeFeaturedPost(postId: string): Observable<any> {
    const url: string = '/api/admin/post/' + postId + '/featured/0';
    return this.httpClient.post(url, {});
  }

  setPostState(postId: string, state: string) {
    const url: string = '/api/post/' + postId + '/state/' + state;
    return this.httpClient.post(url, {});
    // POST /post/{Post_id}/state/ { HIDDEN /  PUBLIC / TAKEN_DOWN}
  }

  getPostFlags(postId: string) {
      return this.httpClient.get('/api/admin/post/' + postId + '/flags', {});
  }

  fetchPost(postId: string) {
      return this.httpClient.get<Post>('/api/admin/post/' + postId + '/info', {});
  }

  changeName(postId: string, newName: string) {
    const url: string = '/api/post/' + postId + '/name';
    return this.httpClient.post(url, {name: newName});
  }

  changeTags(postId: string, tags: string) {
    const url: string = '/api/post/' + postId + '/tags';
    return this.httpClient.put(url, {tags});
  }

  changeDescription(postId: string, newDescription: string) {
    const url: string = '/api/post/' + postId + '/lead/content';
    const params = { content : newDescription };
    return this.httpClient.post(url, params, { responseType : 'text' });
  }

  changeContent(postId: string, newContent: string) {
    const url: string = '/api/post/' + postId + '/premium/content';
    const params = { content : newContent };
    return this.httpClient.post(url, params, { responseType : 'text' });
  }

  setEnableComments(postId: string, enabled: boolean) {
    const url = '/api/post/' + postId + '/comments/' + (enabled ? 'enabled' : 'disabled');
    return this.httpClient.put<any>( url, {});
  }

  private flagReasonsMap() {
    return map((flaggedPosts: IFlaggedPost[]) => flaggedPosts.map(flaggedPost => {
        flaggedPost.reasonStrings = this.parseReasons(flaggedPost.reason);
        return flaggedPost;
      }));
  }

  private parseReasons(reasons: number | string) {
    if (reasons) {
        if (typeof reasons === 'number') {
            reasons = reasons.toString();
        }

        const result: string[] = [];
        for (let i = reasons.length - 1; i >= 0; i--) {
            const j = reasons.length - i - 1;
            const index = Math.pow(2, j);
            if (reasons[i] === '1') {
              result.push(FlagReason[index]);
            }
        }

        return result;
    }
  }
}
