import { Component } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { ConnectableObservable, Observable } from 'rxjs';
import { map, filter, publishReplay, tap } from 'rxjs/operators';

import { UserService } from './../../services/user.service';
import { PostService } from './../../services/post.service';
import { environment } from '@env/environment';

@Component({
  selector: 'postd-bo-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent {
  loaded = false;
  buildDate: string;
  currentPath: ConnectableObservable<string>;
  flaggedPostCount: Observable<number>;
  routerLinkPath: string[];

  constructor(
      private userService: UserService,
      private postService: PostService,
      private router: Router
  ) {
    this.buildDate = environment.buildDate || '';
    this.loaded = false;

    this.userService.curUser$
      .subscribe(user => {
        this.loaded = user && user.admin;

        if (this.loaded) {
          this.flaggedPostCount = this.postService.getFlaggedPosts().pipe(map(posts => posts.length));
        }
      });


    this.currentPath =
      this.router.events.pipe(
        filter(event => event instanceof ActivationEnd),
        tap((event: ActivationEnd) => {
          this.routerLinkPath = event.snapshot.url.map(url => url.path);
        }),
        map((event: ActivationEnd) => event.snapshot.routeConfig.path),
        publishReplay(1)
      ) as ConnectableObservable<string>;
    this.currentPath.connect();


    // this.router.events.pipe(
    //   filter(event => event instanceof ActivationEnd),
    //   map((event: ActivationEnd) => {
    //     return event.snapshot.routeConfig.path;
    //   }),
    //   shareReplay(1)
    // ).subscribe(root => {
    //   console.log(root);
    // });
  }

}
