import { meta } from './meta';
let buildInfo;
try {
  buildInfo = require('./buildInfo');
} catch (err) {
  buildInfo = meta.buildInfo;
}
const proxy = require('../../proxies/proxy-e2e.conf.json');

export const environment = {
  apiServer: proxy['/api'].target,
  version: buildInfo.version,
  buildDate: buildInfo.buildDate,
  enableServiceWorker: false,
  production: false,
  name: 'test',
  transactGroupId: 5203720654553088,
  transactBaseUrl: 'https://test.xsact.com',
  postdBaseUrl: 'https://postd.xsact.com',
  test: true,
  meta,
};
