import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '@shared/types/user';

import { UserService } from './../../services/user.service';



@Component({
  selector: 'postd-bo2-users-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class UsersBrowseComponent implements OnDestroy, OnInit {
  searchParam: UntypedFormControl;
  searchString = '';
  users?: User[];
  page: number;
  endOfResults = false;
  loading = false;
  destroy = new Subject<void>();
  public searchResultsTotal: number;
  public resetSorters: string[];
  public usersPerPage = 30;
  public userLists = new Map<string, User[]>();
  private sorters = ['displayName', 'email1', 'createdDate'];
  private sortingOrder: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.searchParam = new UntypedFormControl();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy))
      .subscribe(params => {
        if (params.query) {
          this.searchString = params.query;
        } else {
          this.searchString = '';
        }
        if (this.searchParam.value !== params.query) {
          this.searchParam.setValue(params.query);
        }

        this.reset();
      });
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  loadUsers(resetList?: boolean) {
    if (this.endOfResults) {
      return;
    }
    this.loading = true;

    this.userService.getUsers(
      this.searchString,
      this.page * this.usersPerPage,
      this.usersPerPage,
      this.sortingOrder
    ).subscribe(result => {
      this.searchResultsTotal = result.total || 0;
      if (!result.users || result.users.length === 0) {
        this.endOfResults = true;
      } else {
        this.users = result.users || [];
      }
      this.loading = false;
    });
    // this.page++;
  }

  search() {
    this.router.navigate(['/', 'users'], { queryParams: { query: this.searchParam.value } });
  }

  resetSearch() {
    this.searchParam.setValue('');
    this.router.navigate(['/', 'users'], { queryParams: { query: '' } });
  }

  public paginationPageChanged(page: number): void {
    this.page = page;
    this.loadUsers();
  }

  private reset() {
    let users = this.userLists.get(this.searchString);
    if (!users) {
      users = [];
      this.userLists.set(this.searchString, users);
    }
    this.users = users;

    this.page = 0;
    this.endOfResults = false;
    this.loadUsers();
  }

  public sortingChanged(ev: any): void {
    this.sortingOrder = (ev.sortType && ev.sortType !== 'default') ?
      ev.sorter + ev.sortType.replace(ev.sortType[0], ev.sortType[0].toUpperCase()) : '';

    this.resetSorters = this.sorters.filter(s => s !== ev.sorter);
    this.loading = true;
    this.page = 0;
    this.loadUsers();
  }
  public exportToCsv(event: Event) {
    location.assign('/api/admin/users/export/csv');
  }
}
