import { Channel } from './channel';
import { Post } from './post';
import { ITransactJWT } from './transact_token';

export enum PaymentType {
  NONE = 0,
  SINGLE_POST = 1,
  CHANNEL_SUBSCRIPTION = 2,
  POST_PLAYLIST = 3,
}

export enum PaymentState {
  // constant members
  NONE = 0,
  STARTED = 1, // token passed to transact but not paid for
  DONE = 2, // PAID
  CANCELED = 3,
  REFUNDED = 4,
}

export enum PaymentProcessor {
  // constant members
  NONE = 0,
  TRANSACT_IO = 1,
  MANUAL = 2,
}

export const SELLER_ID_SELF = 10; // special ID

export class Payment {
  id: string; // PostId_buyerID_timeStamp
  processor: PaymentProcessor = PaymentProcessor.TRANSACT_IO;
  state: PaymentState;
  payType: PaymentType;
  postId: string; // single post
  posts: string[]; // array of posts with POST_PLAYLIST
  channelId: string;
  teamId: string;
  subPlanId: string; // if  a subscription plan save here, otherwise null
  playlistId: string; // if playlist save here otherwise null
  subId: string; // subscription ID
  buyerId: number; // user ID of buyer
  pIdBuyerIdx: string; // column to be indexed combine postID and buyerId. strange name due to 13 char limit
  sellerId: number; // ID of user selling content
  publisherId: number; // publisherId on transact, should be constant but saving just incase
  parentPayId: string; // if this is a component that is nested into other payment list the parent;
  commission_cc: number; // how much we earn if this is a single purchase
  usrEarns_cc: number; // how much we earn if this is a single purchase
  price: number;
  token: ITransactJWT; // token saved
  currency = 'PROD';
  accounted = 0; // set to 1 when include in a PaymentSummary
  created: number; // timestamp
  updated: number;
  channel?: Channel;
  post?: Post;
}

export class ChannelPayment {
  soldPostCount: number;
  soldSubCount: number;
  commission_cc: number;
  usrEarns_cc: number;
  totalSold: number;
  payout: number;
  currency: string;
  id: string;
  periodStamp: string;
  sellerId: number;
  channelId: string;
  period: number;
  periodStart: number;
  endBalance: number;
  startBalance: number;
  updated: number;
  created: number;
}
